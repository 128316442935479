import { IAuthConfig } from "../interface/auth/IAuthService";

const authConfig: IAuthConfig = {
  // Endpoints
  loginEndpoint: "/v1/checkout/",

  // Acessar localstorage
  storageTokenKeyName: "token",
  storageUrlFandiKeyName: "urlFandi",
  storageGuidKeyName: "guid",
  storagePontoVendaKeyName: "pontoVendaId",
  storageWindowKeyName: "windowAccess",
};

export default authConfig;
