import useSubmissionProposal from '@/@core/services/submissionProposal/useSubmissionProposal'; 
import axios from 'axios';
import defaultConfig from '../defaultConfig';

const axiosIns = axios.create( {
  baseURL: defaultConfig.fandiService,
  headers: {
    'Content-Type': "application/json", 
    "fandi-tipo-servico": "checkout",
  }
} );

const { submissionProposal } = useSubmissionProposal( axiosIns, {} );
export default submissionProposal;
