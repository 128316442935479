import useSimulation from "@/services/simulation/useSimulation";
import {
  IResponseSimulation,
  ISimulation,
  ISimulationBody,
} from "@/services/interface/simulation/ISimulation";
import { defineStore } from "pinia";

const useSimulationStore = defineStore("simulationStore", {
  
  state: () => ({
    dataFormResponseSimulation: {} as ISimulation,
  }),

  actions: {
    /**
   * Realizar simulação
   */
    fetchCreatedSimulation( data: ISimulationBody ): Promise<IResponseSimulation> {
      return new Promise((resolve, reject) => {
        useSimulation
          .requestCreateSimulation(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

export default useSimulationStore;

