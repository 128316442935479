
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LTextArea extends Vue {
  @Prop() max!: number;
  @Prop() value!: number;
  @Prop() colorMain!: string;

  get userStyle(): { "--color-primary": string } {
    return {
      "--color-primary": this.colorMain,
    };
  }
}
