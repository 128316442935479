import defaultConfig from "./submissionProposalDefaultConfig";
import AuthService from "@/@core/services/auth/authService";
import { AxiosInstance } from "axios";
import {
  ISubmissionProposalConfig,
  ISubmissionProposalService,
} from "../interface/submissionProposal/ISubmissionProposalService";
import { IRequestSendLink, IResponseGetLink, IResponseSendLink } from "@/services/interface/submissionProposal/ISubmissionProposal";

export default class submissionProposalService
  extends AuthService
  implements ISubmissionProposalService
{
  serviceAreaConfig: ISubmissionProposalConfig;
  constructor(
    axiosIns: AxiosInstance,
    overrideConfig: Record<string, unknown>
  ) {
    super(axiosIns, overrideConfig);
    this.serviceAreaConfig = { ...defaultConfig, ...overrideConfig };
  }

  requestCreateSubmissionProposal(body: IRequestSendLink, clientId: number | null | undefined): Promise<IResponseSendLink> {
    return this.axiosIns.post(`${this.serviceAreaConfig.submissionProposalEndpoint}/${clientId}/link-cadastro`, body);
  }

  requestGetSubmissionProposal(clientId: number | null | undefined, sellerId: number | null | undefined): Promise<IResponseGetLink> {
    return this.axiosIns.get(`${this.serviceAreaConfig.submissionProposalEndpoint}/${clientId}/link-cadastro?vendedorId=${sellerId}`);
  }
}
