import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Wizard from '../views/checkout-wizard/Wizard.vue'
import TesteDrive from '../views/test-drive/TesteDrive.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Wizard',
    component: Wizard
  },
  {
    path: '/test-drive',
    name: 'TesteDrive',
    component: TesteDrive
  },
  {
    path: '/doc',
    name: 'Doc',
    beforeEnter: () => {
      window.location.href = 'https://doc.clickup.com/3006379/p/h/2vqxb-44723/6811ecf4e4aafcf';
    }
  },
  {
    path: '/pt-br/doc',
    name: 'DocPtBr',
    beforeEnter: () => {
      window.location.href = 'https://doc.clickup.com/3006379/p/h/2vqxb-44723/6811ecf4e4aafcf';
    }
  },
  {
    path: '/en/doc',
    name: 'DocEn',
    beforeEnter: () => {
      window.location.href = 'https://doc.clickup.com/3006379/p/h/2vqxb-257080/e4dadbb46545b6c';
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
