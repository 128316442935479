import { AxiosInstance } from "axios";
import AuthService from "./authService";

export default function useAuth(
  axiosIns: AxiosInstance,
  authOverrideConfig: Record<string, unknown>
) {
  const auth = new AuthService(axiosIns, authOverrideConfig);

  return {
    auth,
  };
}
