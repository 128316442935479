import useAuth from '@/@core/services/auth/useAuth';
import axios from 'axios';
import defaultConfig from '../defaultConfig';

const axiosIns = axios.create( {
  baseURL: defaultConfig.checkoutService,
  headers: {
    "Content-Type": "application/json", 
    "fandi-tipo-servico": "checkout",
  }
} );

const { auth } = useAuth( axiosIns, {} );
export default auth;