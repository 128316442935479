
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CButtonForm extends Vue {
  @Prop() text!: string;
  @Prop() icon!: string;
  @Prop({ default: "#2C2D2F" }) defaultColor!: string;

  activateFunctionLink(): void {
    this.$emit("click");
  }

  get userStyle(): {
    "--color-icon": string;
  } {
    return {
      "--color-icon": this.defaultColor,
    };
  }
}
