
import { Component, Prop, Vue } from "vue-property-decorator";

import { IRadioButtonListOption } from "./interface/IRadioButtonListOption";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-select/src/scss/vue-select.scss";

@Component({
  name: "CRadioButtonList",
  components: {
    vSelect,
  },
})
export default class CRadioButtonList extends Vue {
  @Prop({ default: false }) private required!: boolean;
  @Prop() radioButtonListSelect!: Array<IRadioButtonListOption>;
  @Prop() radioButtonListRadio!: Array<IRadioButtonListOption>;
  @Prop() bottomSelect!: string;
  @Prop() placeholder!: string;
  @Prop({ default: "name" }) name!: string;
  @Prop({ default: "large" }) inputsize!: string;
  @Prop({ default: 11 }) maxOptions!: number;
  @Prop({ default: "⚠ Campo obrigatório!" }) dataErrorMenssage!: string;
  @Prop({ default: "" }) modelValue!: string;

  buttonRadio: boolean = true;

  updateChecked(index: number | string): void {
    this.$emit("updateChecked", index);
  }

  updateSelected($event: HTMLInputElement): void {
    if (this.modelValue === null) {
      this.$emit("updateChecked", "");
    } else {
      this.$emit("updateChecked", $event.value);
    }
  }

  updateSelect(): void {
    this.buttonRadio = false;
  }
}
