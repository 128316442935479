
import { Component, Vue } from "vue-property-decorator";
import useSimulationStore from "@/store/simulation";
import useAppConfigStore from "@/store/appConfig";

@Component
export default class CBoxConfirmed extends Vue {
  //Store
  appConfigStore = useAppConfigStore();
  simulationStore = useSimulationStore();

  get nameClient(): string {
    let name = "";
    if (typeof this.appConfigStore.dataFormPerson.fullName.value == "string") {
      name = this.appConfigStore.dataFormPerson.fullName.value;
    }

    return name.split(" ")[0];
  }

  get preApproved(): boolean | undefined {
    if (
      this.simulationStore.dataFormResponseSimulation.retorno ||
      (this.simulationStore.dataFormResponseSimulation.retorno &&
        !this.appConfigStore.isFinishedPositive)
    ) {
      return true;
    } else if (this.simulationStore.dataFormResponseSimulation.message) {
      return false;
    }
    return undefined;
  }
}
