
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CTermOfUse extends Vue {
  @Prop() name!: string;
  @Prop() subtitle!: string;
  @Prop() urlPrivacyPolicy!: string;
  @Prop() urlTermsOfUse!: string;

  privacyPolicy: boolean = false;
  termsOfUse: boolean = false;

  closeTermAndPolicy(): void {
    this.termsOfUse = false;
    this.privacyPolicy = false;
  }

  updatePrivacyPolicy(): void {
    if (this.termsOfUse) {
      this.termsOfUse = !this.termsOfUse;
    }
    this.privacyPolicy = true;
  }

  updateTermsOfUse(): void {
    if (this.privacyPolicy) {
      this.privacyPolicy = !this.privacyPolicy;
    }
    this.termsOfUse = true;
  }

  get title(): string {
    return this.hasName ? this.name + "." : "TERMOS DE USO:";
  }

  get hasName(): boolean {
    const hasName =
      this.name != null && this.name != undefined && this.name != "";

    return hasName;
  }
}
