import useVehicle from '@/@core/services/vehicle/useVehicle'; 
import axios from 'axios';
import defaultConfig from '../defaultConfig';

const axiosIns = axios.create( {
  baseURL: defaultConfig.fandiService,
  headers: {
    'Content-Type': "application/json", 
    "fandi-tipo-servico": "checkout"
  }
} );

const { vehicle } = useVehicle( axiosIns, {} );
export default vehicle;
