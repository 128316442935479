
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

// Store
import useAppConfigStore from "@/store/appConfig";

extend("required", {
  ...required,
  message: "Esse campo é obrigatório",
});

@Component({
  name: "CInput",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CInput extends Vue {
  @Prop() id!: string;
  @Prop() placeholder!: string;
  @Prop() label!: string;
  @Prop({ default: "large" }) inputsize!: string;
  @Prop({ default: false }) required!: boolean;
  @Prop() modelValue!: string;
  @Prop() type!: string;
  @Prop() mask!: string;
  @Prop({ default: "⚠ Campo obrigatório!" }) dataErrorMenssage!: string;
  @Prop({ default: null }) maxLength!: number;

  valueDefault: string = "";

  appConfigStore = useAppConfigStore();

  returnValue(value: string, validate: any): void {
    // Emitir o valor
    this.$emit("updateInput", this.valueDefault);

    // muda validação global
    validate(value).then((result) => {
      this.appConfigStore.$patch((state) => (state.valid = result.valid));
    });
  }

  setValueDefault(): void {
    this.valueDefault = this.modelValue ? this.modelValue : "";
  }

  created(): void {
    this.setValueDefault();
  }
}
