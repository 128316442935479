
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CButton from "@/components/fields-step/CButton/CButton.vue";
import CButtonNotification from "@/components/fields-step/CButtonNotification/CButtonNotification.vue";
import CTextIcon from "@/components/fields-step/CTextIcon/CTextIcon.vue";

import { updatedControllers } from "@/utils/updateControlles";
import { changeStyleAndClass } from "@/utils/updateStyleAndClass";
import { toggleDisabledElement } from "@/utils/disabledAndActivated";
import { removeStyleError } from "@/utils/validations";
import { IInclusionBody } from "@/services/interface/inclusion/IInclusion";
import { ISimulationBody } from "@/services/interface/simulation/ISimulation";
import useAuth from "@/services/auth/useAuth";
import useAuthStore from "@/store/auth";
import useSimulationStore from "@/store/simulation";
import useInclusionStore from "@/store/inclusion";
import useVehicleStore from "@/store/vehicle";
import useAppConfigStore from "@/store/appConfig";
import useSubmissionProposalStore from "@/store/submissionProposal";

@Component({
  name: "WizardFooter",
  components: {
    CButton,
    CTextIcon,
    CButtonNotification,
  },
})
export default class WizardFooter extends Vue {
  @Prop({
    default: "#2F2F2F",
  })
  colorMain!: string;
  @Prop({
    default: "#FFFFFF",
  })
  colorMainFont!: string;

  //Store
  vehicleStore = useVehicleStore();
  appConfigStore = useAppConfigStore();
  authStore = useAuthStore();
  simulationStore = useSimulationStore();
  inclusionStore = useInclusionStore();
  submissionProposalStore = useSubmissionProposalStore();

  //Variáveis auxiliares para resultados da requisição
  validRequestFailed = false;
  validRequestSuccessAux = false;

  failedInclusion = false;

  /**
   * Método padrão do vue para ao iniciar possibilitar avançar com enter
   */
  created(): void {
    document.addEventListener("keydown", this.onEnter);
  }

  /**
   * Função para possibilitar avanço dos steps com enter
   * @param e any
   */
  // eslint-disable-next-line
  onEnter(e: any): void {
    if (!e) {
      e = window.event;
    }

    let keyCode = e.keyCode || e.which;
    if (keyCode == "13") {
      if (e.target.localName == "label" || e.target.localName == "search") {
        document.getElementById(e.target.id)?.click();
      } else if (e.target.localName == "input") {
        document.getElementById("PRÓXIMO")?.click();
      }
    }
  }

  /**
   * Função para validar a chamada do próximo step
   */
  async validNextClick(): Promise<void> {
    //step atual
    const htmlsteps = document.getElementById(this.appConfigStore.nameStep)
      ? document
          .getElementById(this.appConfigStore.nameStep)!
          .getElementsByClassName("step wizard-step current")
      : (0 as any);

    //elemento do step
    const StepField = document!.querySelector(".current");
    const field = StepField!.querySelector(".step-control");
    updatedControllers();

    //Step é válido ou não
    if (htmlsteps[0] ? htmlsteps[0].querySelector(".valid") : false) {
      if (field) {
        field!.classList.remove("input-invalid");
        const spanInvalid = StepField!.querySelector(
          ".span-invalid"
        ) as HTMLSpanElement;
        spanInvalid ? (spanInvalid.style.visibility = "hidden") : "hidden";
      }

      await toggleDisabledElement("PRÓXIMO", true);
      await this.appConfigStore.$patch((state) => (state.valid = true));
      await this.nextStep();
    } else {
      let displayNone = StepField!.querySelector(
        ".span-invalid"
      ) as HTMLSpanElement;

      if (displayNone && displayNone.style) {
        displayNone.style.visibility = "visible";
      }

      //Estilo para as notificações de campos inválidos
      if (field) {
        if (field!.classList) {
          field!.classList.add("input-invalid");
        }

        field!.className += " " + "input-invalid";
      }

      this.appConfigStore.$patch((state) => (state.valid = false));
    }
    await updatedControllers();
  }

  /**
   * Função que ativa a função do botão
   */
  @Watch("validActiveActionButton")
  async activeActionButton(): Promise<void> {
    // Simular
    if (this.appConfigStore.displaySimulate) {
      if (this.isBoxConfirmed()) {
        await this.simulateInclusionStep();
      } else {
        if (this.validStepCurrent()) {
          await this.simulateInclusionStep();
        }
      }
      return;
    }

    // Confirmar
    if (this.appConfigStore.displayConfirm && !this.validRequestFailed) {
      if (this.isBoxConfirmed()) {
        this.finishStep();
      } else {
        if (this.validStepCurrent()) {
          this.finishStep();
        }
      }
      return;
    }

    // Próximo
    if (this.validateButtonNext) {
      if (this.validStepCurrent()) {
        toggleDisabledElement("PRÓXIMO", true);
        this.appConfigStore.$patch((state) => (state.valid = true));
        this.nextStep();
      }
      return;
    }

    //Avançar para tela final
    if (this.visibleButtonProgress) {
      this.appConfigStore.$patch((state) => (state.isFinishedPositive = true));
      this.appConfigStore.$patch(
        (state) => (state.isSubmissionProposal = false)
      );
      this.appConfigStore.$patch((state) => (state.isButtonAdvance = false));
      this.appConfigStore.$patch((state) => (state.isButtonConclude = true));
      return;
    }

    //Concluir checkout
    if (this.visibleButtonConclude) {
      this.appConfigStore.$patch((state) => (state.isButtonAdvance = false));
      this.appConfigStore.$patch(
        (state) => (state.isSubmissionProposal = false)
      );
      if (this.authStore.dataFormResponse.config.linkRedirecionamento) {
        this.abrirLinkCheckout(this.authStore.dataFormResponse.config.linkRedirecionamento);
      }
      return;
    }
  }

  abrirLinkCheckout(url: string): void {
    const element = document.createElement('a');
    element.href = url;
    element.target = '_parent';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  async getLinkSubmissionProposal(): Promise<void> {
    //Avançar para tela final conforme parâmetro
    if (!this.authStore.dataFormResponse.config.exibeTelaCompletarCadastro) {
      this.appConfigStore.$patch((state) => (state.isFinishedPositive = true));
      this.appConfigStore.$patch(
        (state) => (state.isSubmissionProposal = false)
      );
      this.appConfigStore.$patch((state) => (state.isButtonAdvance = false));
      this.appConfigStore.$patch((state) => (state.isButtonConclude = true));
      return;
    }

    const data: {
      clientId: number | null | undefined;
      sellerId: number | null | undefined;
    } = {
      clientId:
        this.simulationStore.dataFormResponseSimulation.retorno?.clienteId,
      sellerId:
        this.simulationStore.dataFormResponseSimulation.retorno?.institucional
          .vendedorId,
    };
    this.appConfigStore.$patch((state) => (state.loading = true));
    await this.submissionProposalStore
      .fetchGetLink(data)
      .then((response) => {
        this.submissionProposalStore.$patch(
          (state) => (state.dataFormResponseGetLink = response.data)
        );
        this.appConfigStore.$patch(
          (state) => (state.isSubmissionProposal = true)
        );
        if (this.authStore.dataFormResponse.config.exibeTelaFinalizacao) {
          this.appConfigStore.$patch((state) => (state.isButtonAdvance = true));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.message != null &&
            error.response.data.message != undefined
          ) {
            this.appConfigStore.$patch(
              (state) =>
                (state.messageRequestFailed = error.response.data.message)
            );
          }
        }
        this.appConfigStore.$patch((state) => (state.requestFailed = true));
      })
      .finally(() =>
        this.appConfigStore.$patch((state) => (state.loading = false))
      );
  }

  async activeActionButtonPrev(): Promise<void> {
    // Voltar
    if (this.validateButtonPrev) {
      await this.prevStep();
      return;
    }

    //Resetar
    if (
      this.visibleButtonRemakeSimulation ||
      this.visibleButtonEditConditions
    ) {
      await this.editConditionsStep();
      return;
    }
  }

  changeStatusStep(
    personStepStatus: boolean,
    vehicleStepStatus: boolean,
    financingStepStatus: boolean,
    nameStepStatus: string
  ): void {
    this.appConfigStore.$patch(
      (state) => (state.isPersonStep = personStepStatus)
    );
    this.appConfigStore.$patch(
      (state) => (state.isVehicleStep = vehicleStepStatus)
    );
    this.appConfigStore.$patch(
      (state) => (state.isFinancingStep = financingStepStatus)
    );
    this.appConfigStore.$patch((state) => (state.nameStep = nameStepStatus));
  }

  /**
   * Função para avançar para o próximo grupo
   * Ou para o proximo step dentro do grupo
   * Ou realiza a simulação
   */
  async nextStep(): Promise<void> {
    if (
      this.appConfigStore.isPersonStep &&
      this.appConfigStore.valueStepPerson == 1
    ) {
      await this.finishStep();
    } else {
      if (!this.appConfigStore.displaySimulate) {
        await this.nextStepInGroup();
      } else {
        await this.simulateInclusionStep();
      }
    }
  }

  /**
   * Função utilizada para avançar para o próximo step
   */
  nextStepInGroup(): void {
    if (!this.appConfigStore.requestFailed) {
      var valueStepProx = this.appConfigStore.valueStepCurrentForGroup + 1;
      if (
        valueStepProx === this.appConfigStore.valueStepCurrent - 1 &&
        this.appConfigStore.isFinancingStep
      ) {
        Promise.resolve().then(() => {
          this.appConfigStore.$patch((state) => (state.displaySimulate = true));
        });
      } else if (
        valueStepProx === this.appConfigStore.valueStepCurrent - 1 &&
        !this.appConfigStore.isFinancingStep
      ) {
        Promise.resolve().then(() => {
          this.appConfigStore.$patch((state) => (state.displayConfirm = true));
        });
      } else {
        Promise.resolve().then(() => {
          this.appConfigStore.$patch((state) => (state.displayConfirm = false));
        });
      }

      changeStyleAndClass(
        "none",
        "step wizard-step",
        this.appConfigStore.valueStepCurrentForGroup
      );
      changeStyleAndClass("block", "step wizard-step current", valueStepProx);
      this.appConfigStore.$patch(
        (state) => (state.valueStepCurrentForGroup = valueStepProx)
      );

      var auxTotalStepCurrent = this.appConfigStore.totalStepCurrent + 1;

      this.appConfigStore.$patch(
        (state) => (state.totalStepCurrent = auxTotalStepCurrent)
      );

      toggleDisabledElement("PRÓXIMO", false);
    }
  }

  /**
   * Função para voltar um step
   */
  async prevStep(): Promise<void> {
    //step atual
    removeStyleError();
    this.appConfigStore.$patch((state) => (state.isButtonAdvance = false));
    this.appConfigStore.$patch((state) => (state.isSubmissionProposal = false));

    this.appConfigStore.$patch((state) => (state.valid = true));
    this.appConfigStore.$patch((state) => (state.displaySimulate = false));

    var valueStepPrev = this.appConfigStore.valueStepCurrentForGroup - 1;

    if (this.appConfigStore.nameStep != "final") {
      changeStyleAndClass(
        "none",
        "step wizard-step",
        this.appConfigStore.valueStepCurrentForGroup
      );
    }
    this.appConfigStore.$patch(
      (state) => (state.valueStepCurrentForGroup = valueStepPrev)
    );

    if (valueStepPrev === this.appConfigStore.valueStepCurrent - 1) {
      await this.appConfigStore.$patch(
        (state) => (state.displayConfirm = true)
      );
    } else {
      await this.appConfigStore.$patch(
        (state) => (state.displayConfirm = false)
      );
    }

    if (valueStepPrev == -1 || this.appConfigStore.nameStep == "final") {
      await this.appConfigStore.$patch(
        (state) => (state.displayConfirm = true)
      );

      if (this.appConfigStore.nameStep == "vehiclestep") {
        this.appConfigStore.$patch((state) => (state.displayConfirm = true));
        this.goToBackStepPerson();
      } else if (this.appConfigStore.nameStep == "financingstep") {
        if (this.appConfigStore.valueStepVehicle > 0) {
          // Caso que existe step vehicle
          this.appConfigStore.$patch((state) => (state.displayConfirm = true));
          this.goToBackStepVehicle();
        }

        if (this.appConfigStore.valueStepVehicle <= 0) {
          // Caso que existe apenas step person
          this.appConfigStore.$patch((state) => (state.displayConfirm = true));
          this.goToBackStepPerson();
        }
      } else if (this.appConfigStore.nameStep == "final") {
        if (this.appConfigStore.valueStepFinancing > 0) {
          // Caso que existe step financing
          await this.appConfigStore.$patch(
            (state) => (state.displayConfirm = false)
          );
          this.appConfigStore.$patch((state) => (state.displaySimulate = true));
          this.goToBackStepFinancing();
        }

        if (
          this.appConfigStore.valueStepFinancing <= 0 &&
          this.appConfigStore.valueStepVehicle > 0
        ) {
          // Caso que existe step vehicle
          this.goToBackStepVehicle();
        }

        if (this.appConfigStore.valueStepVehicle <= 0) {
          // Caso que existe apenas step person
          this.goToBackStepPerson();
        }
      }
    } else {
      changeStyleAndClass("block", "step wizard-step current", valueStepPrev);

      var auxTotalStepCurrent = this.appConfigStore.totalStepCurrent - 1;

      this.appConfigStore.$patch(
        (state) => (state.totalStepCurrent = auxTotalStepCurrent)
      );
    }
  }

  /**
   * Função utilizada para finalizar um grupo de step
   */
  async finishStep(): Promise<void> {
    toggleDisabledElement("CONFIRMAR", true);

    changeStyleAndClass(
      "none",
      "step wizard-step",
      this.appConfigStore.valueStepCurrentForGroup
    );

    this.appConfigStore.$patch((state) => (state.valid = true));

    if (this.appConfigStore.isPersonStep) {
      // Caso que só existe simulação
      if (
        this.appConfigStore.valueStepVehicle <= 0 &&
        this.appConfigStore.valueStepFinancing == 0
      ) {
        await this.simulateInclusionStep();
      }

      // Caso que só existe step financing
      if (
        this.appConfigStore.valueStepVehicle <= 0 &&
        this.appConfigStore.valueStepFinancing > 0
      ) {
        this.goToNextStepFinancing();
      }

      // Caso que existe step vehicle
      if (this.appConfigStore.valueStepVehicle > 0) {
        this.goToNextStepVehicle();
      }
    } else if (this.appConfigStore.isVehicleStep) {
      // Caso que existe step financing
      if (this.appConfigStore.valueStepFinancing > 0) {
        this.goToNextStepFinancing();
      }

      // Caso que só existe simulação
      if (this.appConfigStore.valueStepFinancing <= 0) {
        await this.simulateInclusionStep();
      }
    }

    this.appConfigStore.$patch((state) => (state.displayConfirm = false));

    if (this.appConfigStore.requestFailed) {
      if (!this.failedInclusion && !this.validateFirstStep) {
        this.appConfigStore.$patch((state) => (state.displayConfirm = true));
      }
      return;
    }
    this.appConfigStore.$patch((state) => (state.displaySimulate = false));
    var auxTotalStepCurrent = this.appConfigStore.totalStepCurrent + 1;
    this.appConfigStore.$patch(
      (state) => (state.totalStepCurrent = auxTotalStepCurrent)
    );
    this.appConfigStore.$patch((state) => (state.valueStepCurrentForGroup = 0));

    toggleDisabledElement("CONFIRMAR", false);
  }

  async goToNextStepVehicle(): Promise<void> {
    await this.changeStatusStep(false, true, false, "vehiclestep");
    changeStyleAndClass("block", "step wizard-step current", 0);
    if (this.appConfigStore.valueStepVehicle === 1) {
      this.appConfigStore.$patch((state) => (state.displayConfirm = true));
    }
  }

  async goToNextStepFinancing(): Promise<void> {
    await this.changeStatusStep(false, false, true, "financingstep");
    changeStyleAndClass("block", "step wizard-step current", 0);
    if (this.appConfigStore.valueStepFinancing === 1) {
      this.appConfigStore.$patch((state) => (state.displaySimulate = true));
    }
  }

  async goToBackStepPerson(): Promise<void> {
    //Restaurando dados de finalização
    this.resetDataFinsh();
    this.changeStatusStep(true, false, false, "personstep");
    this.appConfigStore.$patch(
      (state) =>
        (state.valueStepCurrentForGroup =
          this.appConfigStore.valueStepPerson - 1)
    );
    this.appConfigStore.$patch(
      (state) =>
        (state.totalStepCurrent = this.appConfigStore.valueStepPerson - 1)
    );
    if (this.appConfigStore.valueStepPerson == 1) {
      this.appConfigStore.$patch((state) => (state.displayConfirm = false));
    }
    Promise.resolve().then(() => {
      changeStyleAndClass("none", "step wizard-step", 0);
      changeStyleAndClass(
        "block",
        "step wizard-step current",
        this.appConfigStore.valueStepPerson - 1
      );
    });
  }

  async goToBackStepVehicle(): Promise<void> {
    //Restaurando dados de finalização
    this.resetDataFinsh();

    this.changeStatusStep(false, true, false, "vehiclestep");
    this.appConfigStore.$patch(
      (state) =>
        (state.valueStepCurrentForGroup =
          this.appConfigStore.valueStepVehicle - 1)
    );
    this.appConfigStore.$patch(
      (state) =>
        (state.totalStepCurrent =
          this.appConfigStore.valueStepVehicle +
          (this.appConfigStore.valueStepPerson - 1))
    );
    Promise.resolve().then(() => {
      changeStyleAndClass("none", "step wizard-step", 0);
      changeStyleAndClass(
        "block",
        "step wizard-step current",
        this.appConfigStore.valueStepVehicle - 1
      );
    });
  }

  async goToBackStepFinancing(): Promise<void> {
    //Restaurando dados de finalização
    this.resetDataFinsh();

    this.changeStatusStep(false, false, true, "financingstep");

    this.appConfigStore.$patch(
      (state) =>
        (state.valueStepCurrentForGroup =
          this.appConfigStore.valueStepFinancing - 1)
    );

    let auxTotalStepCurrentBackSteoFinancing =
      this.appConfigStore.valueStepFinancing +
      this.appConfigStore.valueStepVehicle +
      (this.appConfigStore.valueStepPerson - 1);
    this.appConfigStore.$patch(
      (state) => (state.totalStepCurrent = auxTotalStepCurrentBackSteoFinancing)
    );
    Promise.resolve().then(() => {
      changeStyleAndClass("none", "step wizard-step", 0);
      changeStyleAndClass(
        "block",
        "step wizard-step current",
        this.appConfigStore.valueStepFinancing - 1
      );
    });
  }

  /**
   * Função para realizar simulação e inclusão na ficha
   */
  async simulateInclusionStep(): Promise<void> {
    const pontoVendaId = useAuth.getPontoVendaId();
    this.failedInclusion = false;

    //formantando variáveis do corpo da requisição
    const trueOrFalse = (value: string | number | boolean) => {
      return value === "Sim" ? true : false;
    };
    const gender =
      this.appConfigStore.dataFormPerson.gender.value === "Feminino"
        ? "F"
        : "M";
    const typeVehicle =
      this.vehicleStore.dataFormVehicle.typeVehicle.value === "Novo"
        ? true
        : false;

    const valueVehicle = () => {
      if (
        typeof this.vehicleStore.dataFormVehicle.priceVehicle.value == "string"
      ) {
        let valuevehicleAux: string = JSON.stringify(
          this.vehicleStore.dataFormVehicle.priceVehicle.value
        ).replace(".", "");
        return parseFloat(JSON.parse(valuevehicleAux));
      } else {
        return this.vehicleStore.dataFormVehicle.priceVehicle.value;
      }
    };

    const valueEntry = () => {
      if (
        typeof this.appConfigStore.dataFormFinancing.entryValue.value ==
        "string"
      ) {
        if (
          this.appConfigStore.dataFormFinancing.entryValue.value.includes(",")
        ) {
          return parseFloat(
            this.appConfigStore.dataFormFinancing.entryValue.value.replace(
              ".",
              ""
            )
          );
        }
        return parseFloat(
          this.appConfigStore.dataFormFinancing.entryValue.value
        );
      }
      return this.appConfigStore.dataFormFinancing.entryValue.value;
    };

    //variável com o corpo da requisição
    const dataSimulation: ISimulationBody = {
      cliente: {
        nome: this.appConfigStore.dataFormPerson.fullName.value,
        pcd: trueOrFalse(this.appConfigStore.dataFormPerson.isPCD.value),
        celular: JSON.stringify(
          this.appConfigStore.dataFormPerson.celphone.value
        ).replace(/[^\d]+/g, ""),
        sexo: gender,
        dataNascimento: this.appConfigStore.dataFormPerson.dateOfBirth.value,
        possuiCnh: trueOrFalse(this.appConfigStore.dataFormPerson.hasCNH.value),
        usoTaxi: trueOrFalse(
          this.appConfigStore.dataFormPerson.commercialPurposes.value
        ),
        cpf: JSON.stringify(
          this.appConfigStore.dataFormPerson.CPF.value
        ).replace(/[^\d]+/g, ""),
        email: this.appConfigStore.dataFormPerson.email.value,
      },
      simulacao: {
        valorEntrada: valueEntry(),
        quantidadeParcelas: this.appConfigStore.dataFormFinancing.parcel.value,
      },
      veiculo: {
        modeloId: this.appConfigStore.versionId,
        valor: valueVehicle(),
        zeroKm: typeVehicle,
        anoFabricacao: this.vehicleStore.dataFormVehicle.manufactureYear.value,
        anoModelo: this.vehicleStore.dataFormVehicle.modelYear.value,
        quilometragem: this.authStore.dataFormResponse.veiculo.quilometragem!,
        cor: this.authStore.dataFormResponse.veiculo.cor!,
        chassi: this.authStore.dataFormResponse.veiculo.chassi!,
        renavam: this.authStore.dataFormResponse.veiculo.renavam!,
        placa: this.authStore.dataFormResponse.veiculo.placa!,
      },
      institucional: {
        empresaId: this.authStore.dataFormResponse.empresaId!,
        pontoVendaId: pontoVendaId,
        vendedorId: this.authStore.dataFormResponse.vendedorId,
      },
    };

    this.appConfigStore.$patch((state) => (state.loading = true));

    await this.simulationStore
      .fetchCreatedSimulation(dataSimulation)
      .then((response) => {
        this.appConfigStore.$patch((state) => (state.displayConfirm = false));
        this.simulationStore.$patch(
          (state) => (state.dataFormResponseSimulation = response.data)
        );
        this.appConfigStore.$patch((state) => (state.loading = false));
        changeStyleAndClass(
          "none",
          "step wizard-step",
          this.appConfigStore.valueStepCurrentForGroup
        );
        toggleDisabledElement("SIMULAR", true);
        this.appConfigStore.$patch((state) => (state.resetData = false));

        this.appConfigStore.$patch((state) => (state.loading = true));
        this.appConfigStore.$patch((state) => (state.isFinished = true));
        this.changeStatusStep(false, false, false, "final");
        this.appConfigStore.$patch((state) => (state.displaySimulate = false));

        var auxTotalStepCurrent = this.appConfigStore.totalStepCurrent + 1;
        this.appConfigStore.$patch(
          (state) => (state.totalStepCurrent = auxTotalStepCurrent)
        );

        toggleDisabledElement("SIMULAR", false);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.message != null &&
            error.response.data.message != undefined
          ) {
            this.appConfigStore.$patch(
              (state) =>
                (state.messageRequestFailed = error.response.data.message)
            );
          }
        }
        this.appConfigStore.$patch((state) => (state.requestFailed = true));
      })
      .finally(() =>
        this.appConfigStore.$patch((state) => (state.loading = false))
      );

    if (this.simulationStore.dataFormResponseSimulation.retorno) {
      this.appConfigStore.$patch((state) => (state.loading = true));

      const quantidadeParcelas = this.simulationStore.dataFormResponseSimulation.retorno.quantidadeParcelas ? this.simulationStore.dataFormResponseSimulation.retorno.quantidadeParcelas : this.appConfigStore.dataFormFinancing.parcel.value;

      //variável com o corpo da requisição
      const dataInclusion: IInclusionBody = {
        clienteId:
          this.simulationStore.dataFormResponseSimulation.retorno!.clienteId,
        urlCallback: this.authStore.dataFormResponse.config.urlCallback,
        veiculo: {
          modeloId: this.appConfigStore.versionId,
          estadoLicenciamento:
            this.simulationStore.dataFormResponseSimulation.retorno!
              .estadoLicenciamento,
          valor: valueVehicle(),
          zeroKm: typeVehicle,
          anoFabricacao:
            this.vehicleStore.dataFormVehicle.manufactureYear.value,
          anoModelo: this.vehicleStore.dataFormVehicle.modelYear.value,
          quilometragem: this.authStore.dataFormResponse.veiculo.quilometragem!,
          cor: this.authStore.dataFormResponse.veiculo.cor!,
          chassi: this.authStore.dataFormResponse.veiculo.chassi!,
          renavam: this.authStore.dataFormResponse.veiculo.renavam!,
          placa: this.authStore.dataFormResponse.veiculo.placa!,
          molicar: this.authStore.dataFormResponse.veiculo.molicar
            ? this.authStore.dataFormResponse.veiculo.molicar
            : null,
        },
        simulacao: {
          valorEntrada:
            this.simulationStore.dataFormResponseSimulation.retorno
              ?.valorEntrada,
          quantidadeParcelas: quantidadeParcelas,
          retornoId:
            this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
              .retornoId,
          possuiIntegracao:
            this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
              .possuiIntegracao,
          sistemaIntegrado:
            this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
              .sistemaIntegrado,
          taxa: {
            id: this.simulationStore.dataFormResponseSimulation.retorno!
              .simulacao.taxa.id,
            anoInicio:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.anoInicio,
            anoFim:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.anoFim,
            codigoIf:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.codigoIf,
            coeficiente:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.coeficiente,
            percentualPlus:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.percentualPlus,
            percentualRetorno:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.percentualRetorno,
            tcCobrada:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.tcCobrada,
            tcDevolvida:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.tcDevolvida,
            cartorio:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.cartorio,
            gravame:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.gravame,
            outras:
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.outras,
            vistoria :
              this.simulationStore.dataFormResponseSimulation.retorno!.simulacao
                .taxa.vistoria,
          },
          menuSelling: {
            listaTemplate: this.simulationStore.dataFormResponseSimulation.retorno!.simulacao.menuSelling?.listaTemplate?.map((template: any) => ({
              templateId: template.templateId,
              listaPlanos: template.listaPlanos.map((plano: any) => ({
                planoId: plano.planoId,
                listaTipoOpcional: plano.listaTipoOpcional.map((tipoOpcional: any) => ({
                  opcionalTipoId: tipoOpcional.opcionalTipoId,
                  listaOpcionalOperacao: tipoOpcional.listaOpcionalOperacao.map((opcional: any) => ({
                    opcionalId: opcional.opcionalId,
                    nome: opcional.nome,
                    descricao: opcional.descricao,
                    excluido: opcional.excluido,
                    comprado: opcional.comprado,
                    tipoCusto: opcional.tipoCusto,
                    custo: opcional.custo,
                    valor: opcional.valor,
                    percentualCusto: opcional.percentualCusto,
                    formaPagamentoCodigo: opcional.formaPagamentoCodigo || null,
                    formaPagamentoId: opcional.formaPagamentoId || null,
                    oferecido: opcional.oferecido,
                    planoGE: opcional.planoGE || null,
                    numItemCotacao: opcional.numItemCotacao || null,
                    ordem: opcional.ordem || null,
                    numeroCalculo: opcional.numeroCalculo || null,
                    numeroOS: opcional.numeroOS || null,
                    valorOriginal: opcional.valorOriginal,
                    planoOpcionalId: opcional.planoOpcionalId || null,
                    codigoCombo: opcional.codigoCombo || null,
                    classificacaoParceiro: opcional.classificacaoParceiro || null,
                    fornecedorParceiro: opcional.fornecedorParceiro || null,
                    ehSPF: opcional.ehSPF,
                    parceiro: opcional.parceiro || null,
                    bloqueado: opcional.bloqueado,
                    valorCotacao : opcional.valorCotacao || null,
                    selecionado : opcional.selecionado,
                    taVisivel : opcional.taVisivel || null,
                  })),
                })),
              })),
            })),
          },
        },
        institucional: {
          empresaId: this.authStore.dataFormResponse.empresaId!,
          pontoVendaId: pontoVendaId,
          vendedorId:
            this.simulationStore.dataFormResponseSimulation.retorno!
              .institucional.vendedorId,
          instituicaoFinanceiraId:
            this.simulationStore.dataFormResponseSimulation.retorno!
              .institucional.instituicaoFinanceiraId,
        },
      };

      if(this.simulationStore.dataFormResponseSimulation.retorno!.simulacao.sistemaEhPrecificacao != undefined 
        && this.simulationStore.dataFormResponseSimulation.retorno!.simulacao.sistemaEhPrecificacao != null 
      ) {
        dataInclusion.simulacao = {
          ...dataInclusion.simulacao,
          sistemaEhPrecificacao: this.simulationStore.dataFormResponseSimulation.retorno!.simulacao.sistemaEhPrecificacao,
        }
      }


      await this.inclusionStore
        .fetchCreatedInclusion(dataInclusion)
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message != null &&
              error.response.data.message != undefined
            ) {
              this.appConfigStore.$patch(
                (state) =>
                  (state.messageRequestFailed = error.response.data.message)
              );
            }
          }

          this.appConfigStore.$patch((state) => (state.requestFailed = true));
          this.failedInclusion = true;
        })
        .finally(() =>
          this.appConfigStore.$patch((state) => (state.loading = false))
        );
    } else if (!this.appConfigStore.requestFailed) {
      this.appConfigStore.$patch((state) => (state.isButtonConclude = true));
    }
  }

  /**
   * Função utilizada para recomeçar o preenchimento do checkout
   */
  async editConditionsStep(): Promise<void> {
    await this.changeStatusStep(true, false, false, "personstep");
    await this.simulationStore.$patch(
      (state) => (state.dataFormResponseSimulation = {})
    );
    this.appConfigStore.$patch((state) => (state.totalStepCurrent = 0));
    this.appConfigStore.$patch((state) => (state.valueStepCurrentForGroup = 0));

    if (
      this.authStore.dataFormResponse.config.permiteAlterarCondicoes ||
      this.authStore.dataFormResponse.config.permiteAlterarCondicoes ==
        undefined ||
      this.authStore.dataFormResponse.config.permiteAlterarCondicoes == null
    ) {
      await this.appConfigStore.$patch((state) => (state.editionSteps = true));
    }

    this.appConfigStore.$patch((state) => (state.isFinished = false));
    this.appConfigStore.$patch((state) => (state.isFinishedPositive = false));
    await this.appConfigStore.$patch(
      (state) => (state.isButtonConclude = false)
    );
    await this.appConfigStore.$patch(
      (state) => (state.isSubmissionProposal = false)
    );
    await this.appConfigStore.$patch(
      (state) => (state.isButtonAdvance = false)
    );
    changeStyleAndClass("block", "step wizard-step current", 0);
    updatedControllers();
  }

  /**
   * Função utilizada para apresenção notificação de erro no botão durante 4s
   */
  @Watch("validRequest")
  @Watch("validRequestSuccess")
  async updateColorButton(): Promise<void> {
    if (
      this.appConfigStore.requestFailed ||
      this.appConfigStore.requestSuccess
    ) {
      let elementSecondary = document.querySelector(
        ".button-secondary"
      ) as HTMLButtonElement;

      if (elementSecondary) {
        elementSecondary!.setAttribute(
          "class",
          "button-secondary button-visibility"
        );
      }

      let elementPrincipal = document.querySelector(
        ".button-principal"
      ) as HTMLButtonElement;

      if (elementPrincipal) {
        elementPrincipal!.setAttribute(
          "class",
          "button-principal button-visibility"
        );
      }

      this.validRequestFailed = this.appConfigStore.requestFailed;
      this.validRequestSuccessAux = this.appConfigStore.requestSuccess;
      setTimeout(() => {
        this.validRequestFailed = false;
        this.validRequestSuccessAux = false;

        let elementSecondary = document.querySelector(
          ".button-secondary"
        ) as HTMLButtonElement;

        if (elementSecondary) {
          elementSecondary!.setAttribute("class", "button-secondary");
        }

        let elementPrincipal = document.querySelector(
          ".button-principal"
        ) as HTMLButtonElement;

        if (elementPrincipal) {
          elementPrincipal!.setAttribute("class", "button-principal");
        }
        this.appConfigStore.$patch((state) => (state.requestFailed = false));
        this.appConfigStore.$patch((state) => (state.requestSuccess = false));
      }, 4000);
    }
  }

  isBoxConfirmed(): boolean {
    const elementStepAtual = document.getElementsByClassName("current")[0];

    if (elementStepAtual!.getElementsByClassName("box-confirmed").length > 0) {
      return true;
    }

    return false;
  }

  validStepCurrent(): boolean {
    //step atual
    const htmlsteps = document
      .getElementById(this.appConfigStore.nameStep)!
      .getElementsByClassName("step wizard-step current") as any;

    //elemento do step
    const StepField = document.querySelector(".current");
    const field = StepField!.querySelector(".step-control");

    //Step é válido ou não
    if (htmlsteps[0].querySelector(".valid")) {
      if (field) {
        field!.classList.remove("input-invalid");
        const spanInvalid = StepField!.querySelector(
          ".span-invalid"
        ) as HTMLSpanElement;
        spanInvalid ? (spanInvalid.style.visibility = "hidden") : null;
      }

      return true;
    } else {
      let displayNone = StepField!.querySelector(
        ".span-invalid"
      ) as HTMLSpanElement;
      displayNone.style.visibility = "visible";

      //Estilo para as notificações de campos inválidos
      if (field!.classList) {
        field!.classList.add("input-invalid");
      } else {
        field!.className += " " + "input-invalid";
      }

      this.appConfigStore.$patch((state) => (state.valid = false));
    }
    updatedControllers();
    return false;
  }

  /**
   * Restaurando dados de finalização
   */
  resetDataFinsh(): void {
    this.appConfigStore.$patch((state) => (state.isButtonAdvance = false));
    this.appConfigStore.$patch((state) => (state.isButtonConclude = false));
    this.appConfigStore.$patch((state) => (state.isSubmissionProposal = false));
    this.simulationStore.$patch(
      (state) => (state.dataFormResponseSimulation = {})
    );
    this.appConfigStore.$patch((state) => (state.isFinishedPositive = false));
    this.appConfigStore.$patch((state) => (state.isFinished = false));
  }

  get validActiveActionButton(): boolean {
    return this.appConfigStore.activeActionButton;
  }

  get validateFirstStep(): boolean {
    if (this.appConfigStore.totalStepCurrent == 0) {
      return true;
    }
    return false;
  }

  get validateButtonNext(): boolean {
    const isValid =
      !this.appConfigStore.displayConfirm &&
      !this.appConfigStore.displaySimulate &&
      !this.validEditConditionsStep &&
      !this.validRequestFailed &&
      !this.appConfigStore.isFinished &&
      !this.validateFirstStep;
    return isValid;
  }

  /**
   * Exibir botão voltar nas telas dos campos e
   * Na tela de confirmar a condição
   */
  get validateButtonPrev(): boolean {
    const isValid =
      (this.validReturnStep || this.validEditStep) &&
      !this.validEditConditionsStep &&
      !this.appConfigStore.isFinished;
    return isValid || this.visibleButtonConfirmConditions;
  }

  get validRequest(): any {
    return this.appConfigStore.requestFailed;
  }

  get validCurrentStep(): boolean {
    return !(
      this.appConfigStore.totalStepCurrent ===
      this.appConfigStore.valueStepTotal
    );
  }

  get validFinalyStep(): boolean {
    return (
      this.appConfigStore.totalStepCurrent ===
      this.appConfigStore.valueStepTotal
    );
  }

  get validReturnStep(): boolean {
    if (this.appConfigStore.isPersonStep) {
      if (this.appConfigStore.valueStepPerson === 2) {
        return (
          !(this.appConfigStore.valueStepCurrentForGroup === 0) &&
          this.appConfigStore.valueStepCurrentForGroup + 1 ===
            this.appConfigStore.valueStepCurrent
        );
      }
    }
    return (
      !(this.appConfigStore.totalStepCurrent === 0) &&
      !(
        !(this.appConfigStore.valueStepCurrentForGroup === 0) &&
        this.appConfigStore.valueStepCurrentForGroup + 1 ===
          this.appConfigStore.valueStepCurrent
      ) &&
      this.validCurrentStep
    );
  }

  get validEditConditionsStep(): boolean {
    if (
      this.appConfigStore.isFinished &&
      (this.simulationStore.dataFormResponseSimulation.retorno ||
        this.simulationStore.dataFormResponseSimulation.message)
    ) {
      return true;
    }
    return false;
  }

  get validEditStep(): boolean {
    if (
      this.appConfigStore.isPersonStep &&
      this.appConfigStore.valueStepPerson === 2
    ) {
      return false;
    }
    return (
      !(this.appConfigStore.valueStepCurrentForGroup === 0) &&
      this.appConfigStore.valueStepCurrentForGroup + 1 ===
        this.appConfigStore.valueStepCurrent
    );
  }

  get validReturnAndEdit(): boolean {
    return this.validReturnStep || this.validEditStep;
  }

  get isValidStep(): boolean {
    return this.appConfigStore.valid;
  }

  /**
   * Retorna a mensagem do botão vinda da requisição
   */
  get mensageButtonFinish(): string {
    return this.authStore.dataFormResponse.config.textoBotaoDestaque
      ? this.authStore.dataFormResponse.config.textoBotaoDestaque
      : "CONCLUIR";
  }

  /**
   * Faz o controle de quando o botão "Editar Condições" é apresentado
   */
  get visibleButtonEditConditions(): boolean | undefined {
    return (
      this.appConfigStore.isFinished &&
      this.simulationStore.dataFormResponseSimulation.retorno &&
      !this.appConfigStore.isFinishedPositive
    );
  }

  /**
   * Faz o controle de quando o botão "Refazer simulação" é apresentado
   * Nas últimas telas, exeto a confirmar condições
   */
  get visibleButtonRemakeSimulation(): boolean | undefined {
    return (
      !this.visibleButtonConfirmConditions &&
      (this.appConfigStore.isFinishedPositive ||
        (this.simulationStore.dataFormResponseSimulation.message &&
          !this.simulationStore.dataFormResponseSimulation.retorno) ||
        this.visibleButtonEditConditions)
    );
  }

  /**
   * Faz o controle de quando o botão "Avançar" é apresentado
   */
  get visibleButtonProgress(): boolean {
    return this.appConfigStore.isButtonAdvance;
  }

  /**
   * Faz o controle de quando o botão "Concluir" é apresentado
   */
  get visibleButtonConclude(): boolean {
    if (!this.authStore.dataFormResponse.config) {
      return false;
    }
    let islinkRedirecionamento = this.authStore.dataFormResponse.config
      .linkRedirecionamento
      ? true
      : false;
    return islinkRedirecionamento && this.appConfigStore.isButtonConclude;
  }

  /**
   * Exibir botão de confirmar condição
   */
  get visibleButtonConfirmConditions(): boolean {
    var preApproved = this.preApproved ? true : false;
    return (
      this.appConfigStore.isFinished &&
      !this.appConfigStore.isFinishedPositive &&
      preApproved &&
      !this.appConfigStore.isSubmissionProposal &&
      (this.authStore.dataFormResponse.config.exibeTelaFinalizacao ||
        this.authStore.dataFormResponse.config.exibeTelaCompletarCadastro)
    );
  }

  get preApproved(): boolean | undefined {
    if (
      this.simulationStore.dataFormResponseSimulation.retorno ||
      (this.simulationStore.dataFormResponseSimulation.retorno &&
        !this.appConfigStore.isFinishedPositive)
    ) {
      return true;
    } else if (this.simulationStore.dataFormResponseSimulation.message) {
      return false;
    }
    return undefined;
  }

  get validRequestSuccess(): boolean {
    return this.appConfigStore.requestSuccess;
  }
}
