import authConfig from "@/@core/services/auth/authDefaultConfig";
import { IDefaultconfig } from "./interface/IDefaultConfig";

const defaultConfig: IDefaultconfig = {
  // Services
  checkoutService: process.env.VUE_APP_URL || "",
  fandiService: localStorage.getItem(authConfig.storageUrlFandiKeyName) || "",
};

export default defaultConfig;
