import { AxiosInstance } from "axios";
import SubmissionProposalService from "./submissionProposalService";

export default function useSubmissionProposal(
  axiosIns: AxiosInstance,
  submissionProposalOverrideConfig: Record<string, unknown>
) {
  const submissionProposal = new SubmissionProposalService(axiosIns, submissionProposalOverrideConfig);

  return {
    submissionProposal,
  };
}
