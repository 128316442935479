import useInclusion from '@/@core/services/inclusion/useInclusion'; 
import axios from 'axios';
import defaultConfig from '../defaultConfig';

const axiosIns = axios.create( {
  baseURL: defaultConfig.fandiService,
  headers: {
    'Content-Type': "application/json", 
    "fandi-tipo-servico": "checkout",
  }
} );

const { inclusion } = useInclusion( axiosIns, {} );
export default inclusion;
