import {
  IResponseBrand,
  IResponseModel,
  IResponseVersion,
} from "@/services/interface/vehicle/IVehicle";
import AuthService from "@/@core/services/auth/authService";
import defaultConfig from "./vehicleDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IVehicleConfig,
  IVehicleService,
} from "../interface/vehicle/IVehicleService";

export default class VehicleService
  extends AuthService
  implements IVehicleService
{
  serviceGroupConfig: IVehicleConfig;
  constructor(
    axiosIns: AxiosInstance,
    overrideConfig: Record<string, unknown>
  ) {
    super(axiosIns, overrideConfig);
    this.serviceGroupConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Requisição para consultar marcas
   * @param  {number} groupId
   * @returns {Promisse}
   */
  requestGetBrand(
    pontoVendaId: string,
    yearManufacture: string,
    yearModel: string
  ): Promise<IResponseBrand> {
    return this.axiosIns
      .get(`${this.serviceGroupConfig.vehicleBrandEndpoint}?PontoVendaId=${pontoVendaId}
        &AnoFabricacao=${yearManufacture}
        &AnoModelo=${yearModel}`);
  }

  /**
   * Requisição para consultar modelo
   * @param  {number} groupId
   * @returns {Promisse}
   */
  requestGetModel(
    pontoVendaId: string,
    yearManufacture: string,
    yearModel: string,
    manuFactureId: string
  ): Promise<IResponseModel> {
    return this.axiosIns
      .get(`${this.serviceGroupConfig.vechicleModelEndpoint}?PontoVendaId= ${pontoVendaId}
        &AnoFabricacao=${yearManufacture}
        &AnoModelo=${yearModel}
        &fabricanteId=${manuFactureId}`);
  }

  /**
   * Requisição para consultar versão
   * @param  {number} groupId
   * @returns {Promisse}
   */
  requestGetVersion(
    pontoVendaId: string,
    yearManufacture: string,
    yearModel: string,
    familyId: string
  ): Promise<IResponseVersion> {
    return this.axiosIns
      .get(`${this.serviceGroupConfig.vechicleVersionEndpoint}?PontoVendaId=${pontoVendaId}
        &AnoFabricacao=${yearManufacture}
        &AnoModelo=${yearModel}
        &FamiliaId=${familyId}`);
  }
}
