import { updateFocus } from "./updateFocus";
import useAppConfigStore from "@/store/appConfig";

/**
 * Função utilizada para apresentar o step ou escoder
 */
  function changeStyleAndClass( styleStep: string, classStep: string, step: number ): void {
    const appConfigStore = useAppConfigStore();

    if(appConfigStore.HTMLCollectionSteps[step]) {
      appConfigStore.HTMLCollectionSteps[step].setAttribute( "style", `display: ${styleStep}` );
      appConfigStore.HTMLCollectionSteps[step].setAttribute( "class", `${classStep}` );
    }

    if( 
      styleStep == "block" && 
      !appConfigStore.HTMLCollectionSteps[ step ].querySelector( ".v-select" ) 
    ) {
      updateFocus( step );
    }
  }

  /**
   * Função utilizada para incrementar F na escala de cores 
   * @param color string
   */
  function incrementColorScale( color: string ): string {
    if( color == "#000000" ) {
      return "#0f0f0f";
    }
    //Separando valores da string
    const valueColorRed = color.slice( 1, -4 );
    const valueColorGreen = color.slice( 3, -2 );
    const valueColorBlue = color.slice( -2 ); 

    //Passando o valor hexadecimal para interiro
    const red = ( parseInt( valueColorRed, 16 ) ) - parseInt( "F", 16 );
		const green = parseInt( valueColorGreen, 16 ) - parseInt( "F", 16 );
		const blue = parseInt( valueColorBlue, 16 ) - parseInt( "F", 16 );

    const colorMonification = "rgb("+ red +","+ green +","+ blue+ ")";

    return colorMonification;
  }

export { changeStyleAndClass, incrementColorScale }