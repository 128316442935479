import defaultConfig from "./inclusionDefaultConfig";
import AuthService from "@/@core/services/auth/authService";
import { AxiosInstance } from "axios";
import {
  IInclusionBody,
  IResponseInclusion,
} from "@/services/interface/inclusion/IInclusion";
import {
  IInclusionConfig,
  IInclusionService,
} from "../interface/inclusion/IInclusionService";

export default class InclusionService
  extends AuthService
  implements IInclusionService
{
  serviceAreaConfig: IInclusionConfig;
  constructor(
    axiosIns: AxiosInstance,
    overrideConfig: Record<string, unknown>
  ) {
    super(axiosIns, overrideConfig);
    this.serviceAreaConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Realiza a inclusão no fandi
   */
  requestCreateInclusion(body: IInclusionBody): Promise<IResponseInclusion> {
    return this.axiosIns.post(this.serviceAreaConfig.inclusionEndpoint, body);
  }
}
