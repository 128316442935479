import useAppConfigStore from "@/store/appConfig";

/**
 * Função utilizada para adicionar foco
 * em elementos label e input
 */

  function updateFocus( index: number ): void {
    const appConfigStore = useAppConfigStore();
    const input = appConfigStore.HTMLCollectionSteps[ index ].querySelector( "input" );
    if( input ) {
      input!.focus();
    } 
  }

export { updateFocus }