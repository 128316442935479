
import { Component, Prop, Vue } from "vue-property-decorator";

//components
import CInputMoney from "@/components/fields-step/CInputMoney/CInputMoney.vue";
import CRadioButtonConditions from "@/components/fields-step/CRadioButtonConditions/CRadioButtonConditions.vue";

//Interface
import { IFormField } from "./interface/IFormField";
import { IRadioButtonConditionsOption } from "@/components/fields-step/CRadioButtonConditions/interface/IRadioButtonConditionsOption";
import useSimulationStore from "@/store/simulation";
import useVehicleStore from "@/store/vehicle";
import useAppConfigStore from "@/store/appConfig";

@Component({
  name: "SimulationFutureStepGroup",
  components: {
    CInputMoney,
    CRadioButtonConditions,
  },
})
export default class SimulationFutureStepGroup extends Vue {
  @Prop({ default: "#2F2F2F" }) colorMain!: string;
  @Prop({ default: "#FFFFFF" }) colorMainFont!: string;

  vehicleStore = useVehicleStore();
  simulationStore = useSimulationStore();
  appConfigStore = useAppConfigStore();

  formSimulation = {
    parcel: {} as IFormField,
    valueParcel: {} as IFormField,
    entryValue: {} as IFormField,
  } as any;

  radioButton: Array<IRadioButtonConditionsOption> = [
    {
      id: 0,
      text: {
        top: 12,
        bottom: "2.850,00",
      },
      value: 0,
    },
    {
      id: 1,
      text: {
        top: 24,
        bottom: "2.850,00",
      },
      value: 1,
    },
    {
      id: 2,
      text: {
        top: 36,
        bottom: "2.910,00",
      },
      value: 2,
    },
    {
      id: 3,
      text: {
        top: 48,
        bottom: "2.963,00",
      },
      value: 3,
    },
    {
      id: 4,
      text: {
        top: 60,
        bottom: "3.900,20",
      },
      value: 4,
    },
  ];

  updateValueParcel(event: string): void {
    //TODO: Console para remover warn dessa função.
    //Será removido quando esse componente passar a ser utilizado
    console.log(event);
  }

  nameUser(): string {
    const name = this.appConfigStore.dataFormPerson.fullName.value;
    return typeof name == "string" ? name.split(" ")[0] : "";
  }

  get preApproved(): boolean | undefined {
    if (this.simulationStore.dataFormResponseSimulation.retorno) {
      return true;
    } else if (this.simulationStore.dataFormResponseSimulation.message) {
      return false;
    }

    return undefined;
  }

  get formatPercentValue(): string {
    const percentValue = parseFloat(
      this.appConfigStore.dataFormFinancing.percentEntryValue.value.toString()
    );

    return percentValue.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  }

  get valueParcel(): string {
    return this.simulationStore.dataFormResponseSimulation.retorno!.valorParcela!.toLocaleString(
      "pt-br",
      { minimumFractionDigits: 2 }
    );
  }

  get valueEntry(): string {
    return this.simulationStore.dataFormResponseSimulation.retorno!.valorEntrada!.toLocaleString(
      "pt-br",
      { minimumFractionDigits: 2 }
    );
  }

  get quantityParcel(): number {
    return this.simulationStore.dataFormResponseSimulation.retorno!
      .quantidadeParcelas!;
  }

  get vehicleManufacturer(): string {
    return this.simulationStore.dataFormResponseSimulation.retorno!.veiculo
      .fabricante!;
  }

  get vehicleFamily(): string {
    return this.simulationStore.dataFormResponseSimulation.retorno!.veiculo
      .familia!;
  }
}
