import { AxiosInstance } from "axios";
import VehicleService from "./vehicleService";

export default function useVehicle(
  axiosIns: AxiosInstance,
  vehicleOverrideConfig: Record<string, unknown>
) {
  const vehicle = new VehicleService(axiosIns, vehicleOverrideConfig);

  return {
    vehicle,
  };
}
