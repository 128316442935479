
import { Component, Vue } from "vue-property-decorator";

import useAuthStore from "@/store/auth";
import useAppConfigStore from "@/store/appConfig";

@Component
export default class CBoxConfirmed extends Vue {
  authStore = useAuthStore();
  appConfigStore = useAppConfigStore();

  /**
   * Retorna a mensagem destaque vinda da requisição ou seu default
   */
  get mensageDestaque(): string {
    return this.authStore.dataFormResponse.config
      .mensagemDestaqueTelaFinalizacao
      ? this.authStore.dataFormResponse.config.mensagemDestaqueTelaFinalizacao!
      : "Agradecemos o seu interesse!";
  }

  /**
   * Retorna a descrição vinda da requisição ou seu default
   */
  get descriptionFinalizationScreen(): string {
    return this.authStore.dataFormResponse.config.descricaoTelaFinalizacao
      ? this.authStore.dataFormResponse.config.descricaoTelaFinalizacao!
      : "Agora você já sabe como encontrar as melhores condições para o financiamento do seu veículo";
  }
}
