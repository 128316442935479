/**
 * Função para validar cpf
 * @param cpf string
 * @returns boolean
 */
function validateCpf( cpf: string ): boolean {
  cpf = cpf.replace( /\D/g, '' );

  if( cpf.toString().length != 11 || /^(\d)\1{10}$/.test( cpf ) ) {
    return false;
  } 

  let result = true;

  [ 9,10 ].forEach( function( j ) {
    let soma = 0, r;
    cpf.split( /(?=)/ ).splice( 0, j ).forEach( function( e, i ) {
        soma += parseInt( e ) * ( ( j + 2 ) - ( i + 1 ) );
    } );

    r = soma % 11;
    r = ( r < 2 ) ? 0 : 11 - r;

    if( r != cpf.substring( j, j + 1 ) ) {
      result = false;
    }
    
  } );

  return result;
}

/**
 * Função para validar o email
 * @param email string
 * @returns boolean
 */
function validateEmail( email: string ): boolean {
  const re = /\S+@\S+\.\S+/;

  const emailFormat = re.test( email );
  const emailSize = email.length <= 50;
  const isValid = emailFormat &&
                  emailSize;
  
  return isValid;
}

/**
 * Função para limpar o select quando alterado um valor anterior relacionado a ele
 * @param name string
 */
function validateSelect( name: string ): void {
  const select = document.getElementById( name )?.querySelector( "button[class=vs__clear]" ) as HTMLButtonElement;
  if( select ) {
    select.click();
  }
}

/**
 * Função para limpar o button radio quando alterado um valor anterior relacionado a ele
 * @param name string
 */
function validateRadio( name: string ): void {
  const querySelectorRadio = "input[type=radio][name=" + name + "]:checked";
  const radio = document.querySelector( querySelectorRadio ) as HTMLInputElement;
  if( radio ) {
    radio.checked = false;
  }
}

/**
 * Função utilizada para validar se um nome é completo
 * @param name string
 * @returns boolean
 */
function validateFullName( name: string ): boolean {
  const arrayName = name.trim().split( ' ' );// Quebrar a string em array de palavras
  
  let isNameValid = true;

  if( arrayName.length < 2 ) {
    isNameValid = false;
  }

  return isNameValid;
}

/**
 * Função utilizada para validar quantidade de dígitos do celular
 * @param name string
 * @returns boolean
 */
function validateCelphone( name: string ): boolean {
  const telefone = name;
  const regex = new RegExp('^\\([1-9]{2}\\) 9[1-9][0-9]{3}\\-[0-9]{4}$');
  return regex.test(telefone);
}

/**
 * Função utilizada para validar a data de nascimento
 * @param name string
 * @returns boolean
 */
function validateBirthDate( name: string ): boolean {

  const dateParts = name.split( '-' );
  const day = Number( dateParts[ 2 ] );
  const month = Number( dateParts[ 1 ] );
  const year = Number( dateParts[ 0 ] );

  const today = new Date();
  const m = today.getMonth() - month;
  let age = today.getFullYear() - year;
  const yearValid = year >= 1901;

  if ( m < 0 && ( today.getDate() < day ) ) {
    age--;
  } 
  
  return age >= 0 &&
    yearValid;
}

/**
 * Função que remove o estilo de erro no elemento
 */
function removeStyleError(): void {
  const Stepfield = document.querySelector( ".current" );
  if( !Stepfield ) {
    return;
  }

  const field = Stepfield!.querySelector( ".step-control" );
  if( field ) {
    field!.classList.remove( "input-invalid" );   
    const spanInvalid = Stepfield!.querySelector( ".span-invalid" ) as HTMLSpanElement
    spanInvalid ? spanInvalid.style.visibility = "hidden": null;
  }
}

export{ 
  validateCpf, 
  validateEmail, 
  validateSelect, 
  validateRadio, 
  validateFullName,
  validateCelphone,
  validateBirthDate,
  removeStyleError,
};