import { IBrand, IResponseBrand, IResponseModel, IResponseVersion, IModel, IVersion } from './../../services/interface/vehicle/IVehicle';
import useVehicle from '@/services/vehicle/useVehicle';
import { IFormVehicle } from '@/views/checkout-wizard/components/VehicleStepGroup/interface/IFormVehicle';
import { defineStore } from "pinia";

const useVehicleStore = defineStore("vehicleStore", {
  /**
   * Obter guid do checkout
   */
  state: () => ({
    dataFormResponseBrand: [] as Array<IBrand>,
    dataFormResponseModel: [] as Array<IModel>,
    dataFormResponseVersion: [] as Array<IVersion>,
    dataFormVehicle: {} as IFormVehicle
  }),

  actions: {
      /**
     * Consultar a lista de marcas
     */
    fetchGetBrand( data: { pontoVendaId: string, yearManufacture: string, yearModel: string } ) : Promise<IResponseBrand>  {
      return new Promise( ( resolve, reject ) => {
        useVehicle
          .requestGetBrand( data.pontoVendaId , data.yearManufacture, data.yearModel )
          .then( ( response ) => { resolve( response ) } )
          .catch( error => { reject( error ) } );
      } );
    },
      /**
     * Consultar a lista de modelos
     */
    fetchGetModel( data: { pontoVendaId: string, yearManufacture: string, yearModel: string, manuFactureId: string } ) : Promise<IResponseModel>  {
      return new Promise( ( resolve, reject ) => {
        useVehicle
          .requestGetModel( data.pontoVendaId, data.yearManufacture, data.yearModel, data.manuFactureId )
          .then( ( response ) => { resolve( response ) } )
          .catch( error => { reject( error ) } );
      } );
    },
      /**
     * Consultar a lista de versão
     */
    fetchGetVersion( data: { pontoVendaId: string, yearManufacture: string, yearModel: string, familyId: string } ) : Promise<IResponseVersion>  {
      return new Promise( ( resolve, reject ) => {
        useVehicle
          .requestGetVersion( data.pontoVendaId, data.yearManufacture, data.yearModel, data.familyId )
          .then( ( response ) => { resolve( response ) } )
          .catch( error => { reject( error ) } );
      } );
    },
  },
});

export default useVehicleStore;
