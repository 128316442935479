
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRadioButtonOption } from "./interface/IRadioButtonOption";

@Component
export default class CRadioButton extends Vue {
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: 6 }) columns!: number;
  @Prop({ default: "name" }) name!: string;
  @Prop() radioButtonList!: Array<IRadioButtonOption>;
  @Prop({ default: "" }) dataErrorMenssage!: string;
  @Prop({ default: null }) valueRadio!: string | number;
  @Prop({ default: false }) ignoreColumns!: boolean;

  updateValue(value: string | number): void {
    this.$emit("updateValue", value);
  }
}
