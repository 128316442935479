import { AxiosInstance } from "axios";
import InclusionService from "./inclusionService";

export default function useInclusion(
  axiosIns: AxiosInstance,
  inclusionOverrideConfig: Record<string, unknown>
) {
  const inclusion = new InclusionService(axiosIns, inclusionOverrideConfig);

  return {
    inclusion,
  };
}
