import { IVehicleConfig } from "./../interface/vehicle/IVehicleService";

const vehicleConfig: IVehicleConfig = {
  //Endpoints
  vehicleBrandEndpoint: "/v1/checkout/Fabricante",
  vechicleModelEndpoint: "Familia",
  vechicleVersionEndpoint: "Modelo",
};

export default vehicleConfig;
