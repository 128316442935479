import { render, staticRenderFns } from "./WizardFooter.vue?vue&type=template&id=78dfe921&scoped=true"
import script from "./WizardFooter.vue?vue&type=script&lang=ts"
export * from "./WizardFooter.vue?vue&type=script&lang=ts"
import style0 from "./WizardFooter.vue?vue&type=style&index=0&id=78dfe921&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78dfe921",
  null
  
)

export default component.exports