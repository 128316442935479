import { IResponseAuth } from "@/services/interface/auth/IAuth";
import authDefaultConfig from "./authDefaultConfig";
import { AxiosInstance } from "axios";
import { IAuthConfig, IAuthService } from "../interface/auth/IAuthService";

export default class AuthService implements IAuthService {
  // Will be used by this service for making API calls
  axiosIns: AxiosInstance;

  // serviceConfig <= Will be used by this service
  serviceConfig: IAuthConfig;

  constructor(
    axiosIns: AxiosInstance,
    authOverrideConfig: Record<string, unknown>
  ) {
    this.axiosIns = axiosIns;
    this.serviceConfig = { ...authDefaultConfig, ...authOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const token: string = this.getToken() || "";
        const urlFandi: string = this.getUrlFandi() || "";

        if (token && config.headers) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = token;
        }

        if (config.baseURL != process.env.VUE_APP_URL) {
          config.baseURL = urlFandi;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  login(guid: string): Promise<IResponseAuth> {
    return this.axiosIns.get(this.serviceConfig.loginEndpoint + guid);
  }

  setToken(token: string): void {
    localStorage.setItem(this.serviceConfig.storageTokenKeyName, token);
  }

  setPontoVendaId(pontoVendaId: number): void {
    const parsed = JSON.stringify(pontoVendaId);
    localStorage.setItem(this.serviceConfig.storagePontoVendaKeyName, parsed);
  }

  setUrlFandi(urlFandi: string): void {
    localStorage.setItem(this.serviceConfig.storageUrlFandiKeyName, urlFandi);
  }

  setGuid(guid: string): void {
    localStorage.setItem(this.serviceConfig.storageGuidKeyName, guid);
  }

  setWindowAccess(windowAccess: string): void {
    localStorage.setItem(this.serviceConfig.storageWindowKeyName, windowAccess);
  }

  getToken(): string | null {
    return localStorage.getItem(this.serviceConfig.storageTokenKeyName);
  }

  getPontoVendaId(): string | null {
    return localStorage.getItem(this.serviceConfig.storagePontoVendaKeyName);
  }

  getUrlFandi(): string | null {
    return localStorage.getItem(this.serviceConfig.storageUrlFandiKeyName);
  }

  getGuid(): string | null {
    return localStorage.getItem(this.serviceConfig.storageGuidKeyName);
  }

  getWindowAccess(): string | null {
    return localStorage.getItem(this.serviceConfig.storageWindowKeyName);
  }
}
