
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class InputDate extends Vue {
  @Prop() id!: string;
  @Prop({ default: false }) required!: boolean;
  @Prop() modelValue!:string;
  @Prop( { default: "⚠ Campo obrigatório!" } ) dataErrorMenssage!: string;
  
  valueDefault:string = "";

  returnValue():void {    
    this.$emit( "updateInput", this.valueDefault );
  }

  setValueDefault(): void {
    this.valueDefault = this.modelValue ?  this.modelValue :  "";
  }

  created(): void {
    this.setValueDefault();
  }
}
