import useSubmissionProposal from "@/services/submissionProposal/useSubmissionProposal";
import { 
  IRequestSendLink, 
  IResponseGetLink, 
  IResponseSendLink, 
  ISubmissionProposalResponse 
} from "@/services/interface/submissionProposal/ISubmissionProposal";
import { defineStore } from "pinia";

const useSubmissionProposalStore = defineStore("submissionProposalStore", {

  state: () => ({
    dataFormResponseGetLink: {} as ISubmissionProposalResponse,
  }),

  actions: {

    /**
   * Enviar link por whatsapp/email
   */
    fetchSendLink( data : { request: IRequestSendLink, clientId: number | null | undefined }): Promise<IResponseSendLink> {
      return new Promise((resolve, reject) => {
        useSubmissionProposal
          .requestCreateSubmissionProposal(data.request, data.clientId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

     /**
   * Busca link para submissão da proposta
   */
    fetchGetLink( data: { clientId: number | null | undefined, sellerId: number | null | undefined }): Promise<IResponseGetLink> {
      return new Promise((resolve, reject) => {
        useSubmissionProposal
          .requestGetSubmissionProposal(data.clientId, data.sellerId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

export default useSubmissionProposalStore;
