import useAppConfigStore from "@/store/appConfig";

/**
 * Esta função é acionada para atualizar o qtd de steps de cada
 * grupo de steps afim de evitar o bug quando se esconde um step.
 */
function updatedControllers(): void {
  const appConfigStore = useAppConfigStore();
  const amountStep1 = document
    .getElementById( "personstep" ) ? document
    .getElementById( "personstep" )!
    .getElementsByClassName( "step" ).length : 0;
  
  const amountStep2 = document
    .getElementById( "vehiclestep" ) ? document
    .getElementById( "vehiclestep" )!
    .getElementsByClassName( "step" ).length : 0;
  
  const amountStep3 = document
    .getElementById( "financingstep" ) ? document
    .getElementById( "financingstep" )!
    .getElementsByClassName( "step" ).length : 0;
  
  const totalStep = amountStep1 + amountStep2 + amountStep3;

  appConfigStore.$patch((state) => (state.valueStepPerson = amountStep1));
  appConfigStore.$patch((state) => (state.valueStepVehicle = amountStep2));
  appConfigStore.$patch((state) => (state.valueStepFinancing = amountStep3));
  appConfigStore.$patch((state) => (state.valueStepTotal = totalStep));


}
export { updatedControllers }