import { defineStore } from "pinia";
import { IFormFinancing } from "@/views/checkout-wizard/components/FinancingStepGroup/interface/IFormFinancing";
import { IFormPerson } from "@/views/checkout-wizard/components/PersonStepGroup/interface/IFormPerson";

const useAppConfigStore = defineStore("AppConfigStore", {
  /**
   * Obter guid do checkout
   */
  state: () => ({
    //valor do step atual por cada grupo  
    valueStepCurrentForGroup: 0 as number,

    //quantidade de steps de todos os grupos até a posição que está
    totalStepCurrent: 0 as number,

    //soma dos steps de todos os grupos e seus valores
    valueStepTotal: 0 as number,
    valueStepPerson: 0 as number,
    valueStepVehicle: 0 as number,
    valueStepFinancing: 0 as number,

    nameStep: "personstep" as string,

    //quantidade do step atual 
    _valueStepCurrent: 0 as number,
    _HTMLCollectionSteps: HTMLCollection,

    dataFormPerson: { } as IFormPerson,
    dataFormFinancing: { } as IFormFinancing,
    versionId: 0,
    
    valid: true as boolean,
    group: '' as string,

    // Flag de ativação de animação de loading
    loading: true as boolean,

    // Flag de ativação de steps ao editar
    editionSteps: false as boolean,

    // Flag de ativação dos botões
    activeActionButton: false as boolean,

    //controle de requisições
    authenticationFailed: false as boolean,
    messageAuthenticationFailed: 'O token informado não é válido ou o tempo limite foi ultrapassado. Por favor, tente novamente.' as string,
    requestFailed: false as boolean,
    messageRequestFailed: 'Por favor, tente novamente.' as string,

    // Controle de estado dos Grupos
    isPersonStep: true as boolean,
    isVehicleStep: false as boolean,
    isFinancingStep: false as boolean,
    isFinished: false as boolean,
    isFinishedPositive: false as boolean,
    displayConfirm: false as boolean,
    displaySimulate: false as boolean,
    buttonAdvance: true as boolean,
    resetData: false as boolean, 
    allActiveSteps: false as boolean,

    isSubmissionProposal: false as boolean,
    isButtonConclude: false as boolean,
    isButtonAdvance: false as boolean,
    requestSuccess: false as boolean,
    titleRequestSuccess: "Ótimo!" as string,
    descriptionRequestSuccess: "Tudo ocorreu conforme o esperado." as string,

    carregandoServicosVeiculosIniciais: false as boolean,
  }),

  getters: {
    valueStepCurrent(): number {
      switch( this.nameStep ) {
        case 'personstep':
          return this.valueStepPerson;
        case 'vehiclestep':
          return this.valueStepVehicle;
        case 'financingstep':
          return this.valueStepFinancing;
        default:
          return this._valueStepCurrent;
      }
    },
    HTMLCollectionSteps(): HTMLCollection {
      const classStep = document
        .getElementById( this.nameStep )!
        .getElementsByClassName( "step" );
      return classStep;
    },
  },
});

export default useAppConfigStore;
