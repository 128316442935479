/**
 * Função paraa setar o atributo disabled de um elemento para true ou false
 * @param element_id string
 * @param disabled boolean
 */
function toggleDisabledElement( element_id: string, disabled: boolean ): void {
  const buttonForDisabled = document.getElementById( element_id ) as HTMLButtonElement;
  if( buttonForDisabled ) {
    buttonForDisabled.disabled = disabled;
  }
}

export { toggleDisabledElement };
