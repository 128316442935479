
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component({
    name: "CInputIcon",
  })
  export default class CInputIcon extends Vue {
    @Prop() id!: string;
    @Prop() iconButton!: string;
    @Prop({ default: "#2C2D2F" }) defaultColor!: string;
    @Prop() modelValue!: string | boolean | number;
    @Prop({ default: "⚠ Campo obrigatório!" }) dataErrorMenssage!: string;
    @Prop({}) load!: boolean;
    @Prop({}) buttonAbility!: boolean;
    @Prop() mask!: string;

    valueDefault: string | boolean | number = "";

    returnValue(): void {
      this.$emit("input", this.valueDefault);
    }

    activateFunction(): void {
      this.$emit("activateFunction");
    }

    setValueDefault(): void {
      this.valueDefault = this.modelValue ? this.modelValue : "";
    }

    created(): void {
      this.setValueDefault();
    }

    get userStyle(): {
      "--color-icon": string;
    } {
      return {
        "--color-icon": this.defaultColor,
      };
    }
}
