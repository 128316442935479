var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.appConfigStore.isFinished &&
    !_vm.appConfigStore.isFinishedPositive &&
    _vm.preApproved &&
    !_vm.appConfigStore.isSubmissionProposal
  )?_c('div',{staticClass:"step-final"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.preApproved != undefined),expression:"preApproved != undefined"}],staticClass:"step current"},[_c('div',{staticClass:"question_title boxConfirmedSimulacao"},[_c('h3',[_c('b',[_vm._v(" Que ótimo, "+_vm._s(_vm.nameClient)+"! ")])]),_c('p',[_vm._v("Encontramos a seguinte condição* para o seu perfil:")])]),_c('div',{staticClass:"box-confirmed container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-6 align-start container-left"},[_c('p',{staticClass:"brand"},[_vm._v(" "+_vm._s(_vm.vehicleManufacturer)+" ")]),_c('p',{staticClass:"model"},[_vm._v(" "+_vm._s(_vm.vehicleFamily)+" ")]),(_vm.authStore.dataFormResponse.config.exibeValorVeiculo)?_c('p',{staticClass:"car-value"},[_vm._v(" Valor do Veículo: R$ "+_vm._s(_vm.vehicleStore.dataFormVehicle.priceVehicle.value)+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-6 align-end container-right"},[_c('p',{staticClass:"parcel"},[_vm._v("Parcelas")]),_c('p',{staticClass:"parcel-value"},[_c('label',[_vm._v(" "+_vm._s(_vm.quantityParcel)+"x ")]),_vm._v(" R$ "+_vm._s(_vm.valueParcel)+" ")]),_c('p',{staticClass:"entry-value"},[_vm._v(" Entrada: R$ "+_vm._s(_vm.valueEntry)+" ("+_vm._s(_vm.formatPercentValue)+"%) ")])])])])]),_vm._m(0)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex m-3"},[_c('span',{staticClass:"span-text"},[_vm._v(" *As condições apresentadas não são garantia de crédito, pois dependem de análise a ser realizada pela instituição financeira concedente. ")])])
}]

export { render, staticRenderFns }