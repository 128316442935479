
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRadioButtonConditionsOption } from "./interface/IRadioButtonConditionsOption";

@Component
export default class CRadioButton extends Vue {
  @Prop() radioButtonList!: Array<IRadioButtonConditionsOption>;
  @Prop( { default: false } ) required!: boolean;
  @Prop( { default: 6 } ) columns!: number;
  @Prop( { default: "name" } ) name!: string;
  @Prop( { default: "" } ) dataErrorMenssage!: string;
  @Prop( { default: null } ) valueRadio!: string | number;
  @Prop( { default: "" } ) defaultColor!: string;
  @Prop( { default: "" } ) defaultColorFont!: string;

  valueRadioComponente = this.valueRadio;

  updateValue( value: number ) : void {
    this.$emit( 'updateValue', value );
  }

  get userStyleConditions() : { 
    "--color-primary": string, 
    "--color-opacity-primary": string,
  } {
    return {
      "--color-primary": this.defaultColor,
      "--color-opacity-primary": `${ this.defaultColor }1A`
    };
  }
}
