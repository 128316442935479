import CheckoutClass from '@/checkoutClass';

/**
 * Inicializações
 */
export default new CheckoutClass( '#app', { /* config object */ } );

const app = document.querySelector( '#app' );

if( app instanceof HTMLElement ) {
  app.addEventListener( 'some-event', function( e ) {
    if( e instanceof CustomEvent ) {
      console.log( e.detail );
    }
  } );
}