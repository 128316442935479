<template>
  <div class="container mt-3 mb-3">
    <img src="@/assets/logo.png" alt="logo" class="max-img m-3 img-fluid" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h3>Configurações visuais</h3>

        <div class="form-group mb-2">
          <label for="logo">URL para logo da marca</label>
          <input class="form-control" id="logo" type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="cordestaque">Cor de destaque</label>
          <input
            value="#2F2F2F"
            class="form-control"
            id="cordestaque"
            type="color"
          />
        </div>
        <div class="form-group mb-2">
          <label for="corfonte">Cor da fonte</label>
          <input
            value="#ffffff"
            class="form-control"
            id="corfonte"
            type="color"
          />
        </div>

        <div class="form-group mb-2">
          <label for="mensagemTelaFinal"
            >Mensagem da tela de destaque da tela de finalização</label
          >
          <input id="mensagemTelaFinal" class="form-control" type="text" />
        </div>

        <div class="form-group mb-2">
          <label for="descricaoTelaFinalizacao"
            >Descrição da tela de finalização</label
          >
          <input
            id="descricaoTelaFinalizacao"
            class="form-control"
            type="text"
          />
        </div>

        <div class="form-group mb-2">
          <label for="fecharBotao">Ação Botão Destaque</label>
          <input id="fecharBotao" class="form-control" type="text" />
        </div>

        <div class="form-group mb-2">
          <label for="TextoBotao">Texto do botão destaque</label>
          <input id="TextoBotao" class="form-control" type="text" />
        </div>

        <div class="form-group mb-2">
          <label for="linkRedirecionamento">Link de redirecionamento</label>
          <input id="linkRedirecionamento" class="form-control" type="text" />
        </div>

        <div class="form-group mb-2">
          <label class="col-12">Apresentar box de confirmação</label>
          <div class="col-12 d-flex">
            <input
              class="form-check-input"
              type="checkbox"
              id="dadosPessoais"
            />
            <label class="form-check-label" for="dadosPessoais">
              Confirmar Dados Pessoais
            </label>
          </div>
          <div class="col-12 d-flex">
            <input
              class="form-check-input mr-1"
              type="checkbox"
              id="dadosVeiculo"
            />
            <label class="form-check-label" for="dadosVeiculo">
              Confirmar Dados do Veiculo
            </label>
          </div>
          <div class="col-12 d-flex">
            <input
              class="form-check-input mr-1"
              type="checkbox"
              id="dadosFinanciamento"
            />
            <label class="form-check-label" for="dadosFinanciamento">
              Confirmar Dados do Financiamento
            </label>
          </div>
        </div>

        <div class="form-group mb-2">
          <label for="exibirTela">Exibir tela de finalização</label>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="exibirTela"
              id="exibirTelaS"
              value="true"
            />
            <label class="form-check-label" for="exibirTelaS"> Sim </label>
          </div>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="exibirTela"
              id="exibirTelaN"
              value="false"
            />
            <label class="form-check-label" for="exibirTelaN"> Não </label>
          </div>
        </div>

        <div class="form-group mb-2">
          <label for="permiteAlterar">Permitir alterar as condições</label>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="permiteAlterar"
              id="permiteAlterarS"
              value="true"
            />
            <label class="form-check-label" for="permiteAlterarS"> Sim </label>
          </div>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="permiteAlterar"
              id="permiteAlterarN"
              value="false"
            />
            <label class="form-check-label" for="permiteAlterarN"> Não </label>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h3>Dados do cliente</h3>

        <div class="form-group mb-2">
          <label for="nome">Nome do Cliente</label>
          <input id="nome" class="form-control" type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="celular">Celular</label>
          <input id="celular" class="form-control" type="text" />
        </div>

        <div class="form-group mb-2">
          <label for="data">Data de Nascimento</label>
          <input id="data" class="form-control" type="date" />
        </div>

        <div class="form-group mb-2">
          <label for="cpf">CPF</label>
          <input id="cpf" class="form-control" type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="email">Email</label>
          <input id="email" class="form-control" type="email" />
        </div>

        <div class="form-group mb-2">
          <label>PCD</label>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="pcd"
              id="pcd1"
              value="true"
            />
            <label class="form-check-label" for="pcd1"> Sim </label>
          </div>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="pcd"
              id="pcd2"
              value="false"
            />
            <label class="form-check-label" for="pcd2"> Não </label>
          </div>
        </div>

        <div class="form-group mb-2">
          <label for="sexo">Sexo</label>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="sexo"
              id="sexo1"
              value="F"
            />
            <label class="form-check-label" for="sexo1"> Feminino </label>
          </div>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="sexo"
              id="sexo2"
              value="M"
            />
            <label class="form-check-label" for="sexo2"> Masculino </label>
          </div>
        </div>

        <div class="form-group mb-2">
          <label for="cnh">Possui CNH?</label>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="cnh"
              id="cnh1"
              value="true"
            />
            <label class="form-check-label" for="cnh1"> Sim </label>
          </div>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="cnh"
              id="cnh2"
              value="false"
            />
            <label class="form-check-label" for="cnh2"> Não </label>
          </div>
        </div>

        <div class="form-group mb-2">
          <label for="carro">Para fins comerciais?</label>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="carro"
              id="carro1"
              value="true"
            />
            <label class="form-check-label" for="carro1"> Sim </label>
          </div>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="carro"
              id="carro2"
              value="false"
            />
            <label class="form-check-label" for="carro2"> Não </label>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h3>Dados do veículo</h3>

        <div class="form-group mb-2">
          <label for="molicar">Molicar</label>
          <input id="molicar" class="form-control" type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="fipe">Fipe</label>
          <input id="fipe" class="form-control" type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="modelo">Ano do modelo</label>
          <input id="modelo" class="form-control" type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="fabricacao">Ano da Fabricação</label>
          <input id="fabricacao" class="form-control" type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="valorveiculo">Valor do veículo</label>
          <input id="valorveiculo" class="form-control" type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="zerokm">Carro zeroKm</label>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="zerokm"
              id="pcdzerokm1"
              value="true"
            />
            <label class="form-check-label" for="zerokm1"> Sim </label>
          </div>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="radio"
              name="zerokm"
              id="zerokm2"
              value="false"
            />
            <label class="form-check-label" for="zerokm2"> Não </label>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h3>Dados da simulação</h3>

        <div class="form-group mb-2">
          <label for="valorentrada">Valor da entrada</label>
          <input id="valorentrada" class="form-control" type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="parcelas">Quantidade de parcelas</label>
          <input id="parcelas" class="form-control" type="text" />
        </div>
      </div>
    </div>
    <span id="span-botao"></span>
    <button
      id="botao"
      @click="chamarObterGuid()"
      class="btn btn-primary color-button"
    >
      Abrir checkout
    </button>
  </div>
</template>

<script>
export default {
  name: "TesteDrive",
  methods: {
    requsicaoPost(url, data) {
      let request = new XMLHttpRequest();
      request.open("POST", url, true);
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("fandi-tipo-servico", "checkout");
      request.send(JSON.stringify(data));
      request.onload = function () {
        if (JSON.parse(this.responseText).retorno == undefined) {
          document.getElementById("span-botao").textContent =
            "Error: Tente abri o checkout novamente.";
          document.getElementById("botao").textContent = "Abrir checkout";
          document.getElementById("botao").disabled = false;
        } else {
          window.open(
            `${window.location.origin}/?token=${
              JSON.parse(this.responseText).retorno
            }`
          );
          document.getElementById("botao").textContent = "Abrir checkout";
          document.getElementById("botao").disabled = false;
        }
      };
      return JSON.parse(this.responseText).retorno;
    },
    chamarObterGuid() {
      event.preventDefault();
      document.getElementById("botao").disabled = true;
      document.getElementById("botao").textContent =
        "Abrindo checkout, aguarde...";
      let url = `${process.env.VUE_APP_URL}v1/checkout/obter-guid`;

      let pcd =
        document.querySelector("input[name=pcd]:checked") === null
          ? null
          : Boolean(
              document.querySelector("input[name=pcd]:checked").value == "true"
            );
      let sexo =
        document.querySelector("input[name=sexo]:checked") === null
          ? null
          : document.querySelector("input[name=sexo]:checked").value;
      let possuiCnh =
        document.querySelector("input[name=cnh]:checked") === null
          ? null
          : Boolean(
              document.querySelector("input[name=cnh]:checked").value == "true"
            );
      let usoTaxi =
        document.querySelector("input[name=carro]:checked") === null
          ? null
          : Boolean(
              document.querySelector("input[name=carro]:checked").value ==
                "true"
            );
      let zeroKm =
        document.querySelector("input[name=zerokm]:checked") === null
          ? null
          : Boolean(
              document.querySelector("input[name=zerokm]:checked").value ==
                "true"
            );
      let exibirTelaFinal =
        document.querySelector("input[name=exibirTela]:checked") === null
          ? null
          : Boolean(
              document.querySelector("input[name=exibirTela]:checked").value ==
                "true"
            );
      let permitirAlterarCondicoes =
        document.querySelector("input[name=permiteAlterar]:checked") === null
          ? null
          : Boolean(
              document.querySelector("input[name=permiteAlterar]:checked")
                .value == "true"
            );

      let confirmarDados = [];

      document.getElementById("dadosPessoais").checked
        ? confirmarDados.push("ConfirmarDadosPessoais")
        : "";
      document.getElementById("dadosVeiculo").checked
        ? confirmarDados.push("ConfirmarDadosVeiculo")
        : "";
      document.getElementById("dadosFinanciamento").checked
        ? confirmarDados.push("ConfirmarDadosFinanciamento")
        : "";

      let data = {
        config: {
          chaveAcesso: process.env.VUE_APP_CHAVE_ACESSO || "",
          cnpjLoja: process.env.VUE_APP_CNPJ_LOJA || "",
          urlLogomarca:
            document.getElementById("logo").value == ""
              ? null
              : document.getElementById("logo").value,
          corFundo: "#123456",
          corDestaque:
            document.getElementById("cordestaque").value == ""
              ? null
              : document.getElementById("cordestaque").value,
          corFonteBotao:
            document.getElementById("corfonte").value == ""
              ? null
              : document.getElementById("corfonte").value,
          urlCallback: "http://teste.com.br/",
          confirmarDados: confirmarDados,
          vendedorId: null,
          exibeTelaFinalizacao: exibirTelaFinal,
          mensagemDestaqueTelaFinalizacao:
            document.getElementById("mensagemTelaFinal").value == ""
              ? null
              : document.getElementById("mensagemTelaFinal").value,
          descricaoTelaFinalizacao:
            document.getElementById("descricaoTelaFinalizacao").value == ""
              ? null
              : document.getElementById("descricaoTelaFinalizacao").value,
          acaoBotaoDestaque: "F",
          textoBotaoDestaque:
            document.getElementById("TextoBotao").value == ""
              ? null
              : document.getElementById("TextoBotao").value,
          linkRedirecionamento:
            document.getElementById("linkRedirecionamento").value == ""
              ? null
              : document.getElementById("linkRedirecionamento").value,
          permiteAlterarCondicoes: permitirAlterarCondicoes,
        },
        cliente: {
          nome:
            document.getElementById("nome").value == ""
              ? null
              : document.getElementById("nome").value,
          pcd: pcd,
          celular:
            document.getElementById("celular").value == ""
              ? null
              : document.getElementById("celular").value,
          sexo: sexo,
          dataNascimento:
            document.getElementById("data").value == ""
              ? null
              : document.getElementById("data").value,
          possuiCnh: possuiCnh,
          usoTaxi: usoTaxi,
          cpf:
            document.getElementById("cpf").value == ""
              ? null
              : document.getElementById("cpf").value,
          email:
            document.getElementById("email").value == ""
              ? null
              : document.getElementById("email").value,
        },
        simulacao: {
          valorEntrada:
            document.getElementById("valorentrada").value == ""
              ? null
              : parseFloat(document.getElementById("valorentrada").value),
          quantidadeParcelas:
            document.getElementById("parcelas").value == ""
              ? null
              : parseInt(document.getElementById("parcelas").value),
        },
        veiculo: {
          molicar:
            document.getElementById("molicar").value == ""
              ? null
              : document.getElementById("molicar").value,
          fipe:
            document.getElementById("fipe").value == ""
              ? null
              : document.getElementById("fipe").value,
          placa: "",
          codigoParceiro: "abc",
          anoModelo:
            document.getElementById("modelo").value == ""
              ? null
              : parseInt(document.getElementById("modelo").value),
          anoFabricacao:
            document.getElementById("fabricacao").value == ""
              ? null
              : parseInt(document.getElementById("fabricacao").value),
          valorVeiculo:
            document.getElementById("valorveiculo").value == ""
              ? null
              : parseFloat(document.getElementById("valorveiculo").value),
          zeroKm: zeroKm,
          renavam: "12345678901",
          chassi: "LagaTingaLagating",
          cor: "ROXO",
          quilometragem: 0,
        },
      };
      this.requsicaoPost(url, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.max-img {
  max-width: 14rem;
}

.color-button {
  background-color: #1ecad3;
  border-color: #1ecad3;
}
</style>
