import {
  IInclusionBody,
  IResponseInclusion,
} from "@/services/interface/inclusion/IInclusion";
import useInclusion from "@/services/inclusion/useInclusion";
import { defineStore } from "pinia";

const useInclusionStore = defineStore("inclusionStore", {
  
  actions: {
    /**
   * Realizar a inclusão da ficha
   */
    fetchCreatedInclusion( data: IInclusionBody ): Promise<IResponseInclusion> {
      return new Promise((resolve, reject) => {
        useInclusion
          .requestCreateInclusion(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

export default useInclusionStore;

