import { AxiosInstance } from "axios";
import SimulationService from "./simulationService";

export default function useSimulation(
  axiosIns: AxiosInstance,
  simulationOverrideConfig: Record<string, unknown>
) {
  const simulation = new SimulationService(axiosIns, simulationOverrideConfig);

  return {
    simulation,
  };
}
