
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { VMoney } from 'v-money';

@Component({
  name: "CInputMoney",
  directives:  { 
    money: VMoney
  }
})
export default class CInputMoney extends Vue {
  @Prop() id!: string;
  @Prop() modelValue!:string;
  @Prop( { default: 9999999.99 } ) max!: number;
  @Prop( { default: false } ) required!: boolean;
  @Prop( { default: false } ) negativeValue!: string;
  @Prop( { default: "⚠ Campo obrigatório!" } ) dataErrorMenssage!: string;
  @Prop( { default: "primary" } ) variant!: string;

  valueDefault:string = "";

  @Watch( 'valueDefault', { immediate: true } )
  removeNegative() : void {
    if( !this.negativeValue ) {
      this.valueDefault = this.valueDefault.replace( '-', '' )
    }

    let value = parseInt( this.valueDefault.replaceAll( '.', '' ).replaceAll( ',', '.' ) );

    if( value > this.max ) {
      this.valueDefault = ( this.max ).toLocaleString( 'pt-br', { minimumFractionDigits: 2 } );
    }
  }

  returnValue() : void {
    this.$emit( "updateInput", this.valueDefault );
  }

  setValueDefault() : void {
    if( !this.modelValue ) {
      this.valueDefault = "";
    } else {
      let modelValueAux = ( parseFloat( this.modelValue ) ).toLocaleString( 'pt-br', { minimumFractionDigits: 2 } );
      this.valueDefault = modelValueAux;
    }
  }

  created() : void {
    this.setValueDefault();
  }
}
