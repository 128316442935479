
import { Component, Prop, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-select/src/scss/vue-select.scss";

@Component({
  name: "CSelectSearch",
  components: {
    vSelect,
  },
})
export default class CSelectSearch extends Vue {
  @Prop() id!: string;
  @Prop() placeholder!: string;
  @Prop() label!: string;
  @Prop() options!: Array<string>;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: "" }) modelValueSelect!: string;
  @Prop({ default: "⚠ Campo obrigatório!" }) dataErrorMenssage!: string;

  valueDefault: string | boolean | number = "";

  updateSelected($event: any): void {
    this.$emit("updateSelected", $event);
  }
}
