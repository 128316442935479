import defaultConfig from "./simulationDefaultConfig";
import AuthService from "@/@core/services/auth/authService";
import { AxiosInstance } from "axios";
import {
  IResponseSimulation,
  ISimulationBody,
} from "@/services/interface/simulation/ISimulation";
import {
  ISimulationConfig,
  ISimulationService,
} from "../interface/simulation/ISimulationsService";

export default class SimulationService
  extends AuthService
  implements ISimulationService
{
  serviceAreaConfig: ISimulationConfig;
  constructor(
    axiosIns: AxiosInstance,
    overrideConfig: Record<string, unknown>
  ) {
    super(axiosIns, overrideConfig);
    this.serviceAreaConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Cria uma simulação e retorna os dados para a inclusão
   */
  requestCreateSimulation(body: ISimulationBody): Promise<IResponseSimulation> {
    return this.axiosIns.post(this.serviceAreaConfig.simulationEndpoint, body);
  }
}
