
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDataConfirmed } from "./interface/IDataConfirmed"

@Component
export default class CBoxConfirmed extends Vue {
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() dataConfirmed!: Array<IDataConfirmed>;
  @Prop() classConfirmed!: string;

}
