var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isSubmissionProposal)?_c('div',{staticClass:"step-final step-submission-proposal"},[_c('div',{staticClass:"step current"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex flex-column align-items-center d-none d-sm-flex col-6"},[_c('qrcode-vue',{staticClass:"container-qr-code",attrs:{"size":"150","value":_vm.submissionProposalStore.dataFormResponseGetLink.url}}),_c('span',{staticClass:"span-qr-code mt-2"},[_vm._v("Aponte a câmera e acesse o cadastro.")])],1),_c('div',{staticClass:"d-flex flex-column justify-content-evenly col-12 col-sm-6"},[(_vm.visiblieButtonLink)?_c('c-button-form',{attrs:{"id":"button-link","text":"Preencher agora","icon":"pencil-square","defaultColor":"#000000"},on:{"click":_vm.openLinkRedirect}}):_vm._e(),(
            _vm.submissionProposalStore.dataFormResponseGetLink
              .permiteEnvioWhatsApp
          )?_c('div',{staticClass:"form-group-whatsapp"},[(_vm.visiblieButtonWhatsapp)?_c('c-button-form',{attrs:{"id":"button-whatsapp","text":"Receber via whatsapp","icon":"whatsapp","defaultColor":"#25D366"},on:{"click":function($event){_vm.ehButtonWhatsapp = false}}}):_vm._e(),(_vm.visiblieInputWhatsapp)?_c('label',{staticClass:"span-input-submission",attrs:{"for":"proposal-celphone"}},[_vm._v("Informe o seu celular: ")]):_vm._e(),(_vm.visiblieInputWhatsapp)?_c('c-input-icon',{attrs:{"id":"proposal-celphone","classInputButton":"proposal-celphone","iconButton":"whatsapp","placeholder":"(00) 00000-0000","defaultColor":"#25D366","mask":"(##) #####-####","modelValue":_vm.celphone.value,"dataErrorMenssage":_vm.celphone.messageError,"load":_vm.celphoneLoad,"buttonAbility":_vm.celphoneAbility},on:{"activateFunction":function($event){return _vm.sendLinkWhatsapp()},"input":function($event){return _vm.validInputCelphone($event)}}}):_vm._e()],1):_vm._e(),(
            _vm.submissionProposalStore.dataFormResponseGetLink.permiteEnvioEmail
          )?_c('div',{staticClass:"form-group-email"},[(_vm.visiblieButtonEmail)?_c('c-button-form',{attrs:{"id":"button-email","text":"Receber via e-mail","icon":"envelope","defaultColor":"#103F6A"},on:{"click":function($event){_vm.ehButtonEmail = false}}}):_vm._e(),(_vm.visiblieInputEmail)?_c('label',{staticClass:"span-input-submission",attrs:{"for":"proposal-email"}},[_vm._v("Informe o seu e-mail: ")]):_vm._e(),(_vm.visiblieInputEmail)?_c('c-input-icon',{attrs:{"id":"proposal-email","classInputButton":"proposal-email","iconButton":"envelope","placeholder":"exemplo@mail.com","defaultColor":"#103F6A","modelValue":_vm.email.value,"dataErrorMenssage":_vm.email.messageError,"load":_vm.emailLoad,"buttonAbility":_vm.emailAbility},on:{"activateFunction":function($event){return _vm.sendLinkEmail()},"input":function($event){return _vm.validInputEmail($event)}}}):_vm._e()],1):_vm._e(),(_vm.infoLinkVisible)?_c('span',{staticClass:"info-link d-flex"},[_c('i',{staticClass:"ph ph-info"}),_vm._v(" O link enviado ficará disponível por 24h ")]):_vm._e()],1)])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"boxConfirmedFinishPositive"},[_c('h3',{staticClass:"title-submission"},[_c('b',[_vm._v("Gostou da condição encontrada na simulação?")])]),_c('p',{staticClass:"subtitle-submission"},[_vm._v(" Você pode solicitar uma análise completa do seu crédito, para isso basta preencher mais alguns dados nas opções abaixo: ")])])
}]

export { render, staticRenderFns }