
import { Component, Prop, Vue } from "vue-property-decorator";
import { incrementColorScale } from "@/utils/updateStyleAndClass";

@Component
export default class CButton extends Vue {
  @Prop() className!: string;
  @Prop() name!: string;
  @Prop() defaultColor!: string;
  @Prop() defaultColorFont!: string;
  @Prop({default: true}) disable!: boolean;
  
  activateFunction(): void {
    this.$emit('activateFunction');
  }

  get userStyle(): { 
    "--color-primary": string, 
    "--color-secondary": string,
    "--color-hover-primary": string,
    "--color-hover-secondary": string, 
  } {
    return {
      "--color-primary": this.defaultColor,
      "--color-secondary": this.defaultColorFont,
      "--color-hover-primary": incrementColorScale( this.defaultColor ),
      "--color-hover-secondary": incrementColorScale( this.defaultColorFont )
    };
  }
}
