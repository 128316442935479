import { IResponseAuth, IResponse } from "../../services/interface/auth/IAuth";
import useAuth from "@/services/auth/useAuth";
import { defineStore } from "pinia";

const useAuthStore = defineStore("authStore", {
  /**
   * Obter guid do checkout
   */
  state: () => ({
    _dataFormResponse: {} as IResponse,
  }),

  getters: {
    dataFormResponse(): IResponse {
      return this._dataFormResponse;
    },
  },

  actions: {
    fetchLogin(guid: string | ""): Promise<IResponseAuth> {
      return new Promise((resolve, reject) => {
        useAuth
          .login(guid)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    setDataFormResponse(value: IResponse): void {
      this._dataFormResponse = value;
    },
  },
});

export default useAuthStore;
