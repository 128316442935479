
import { Component, Prop, Vue } from "vue-property-decorator";
import LProgressBar from "@/components/fields-step/LProgressBar/LProgressBar.vue";

//Store
import useAppConfigStore from "@/store/appConfig";

@Component({
  name: "WizardHeader",
  components: {
    LProgressBar,
  },
})
export default class WizardHeader extends Vue {
  @Prop({
    default: require("@/assets/logo.png"),
  })
  defaultImage!: string;
  @Prop() nameStep!: string;
  @Prop() companyName!: string;
  @Prop() colorMain!: string;

  appConfigStore = useAppConfigStore();

  get hasTitle(): boolean {
    return (
      this.defaultImage === "/img/logo.732992e9.png" &&
      this.companyName !== undefined
    );
  }
}
