import 'vue-gtag';

/**
 * Função que transforma um página no google analytics e
 * Conta como mais uma visita caso já a página tenha sido criada
 * @param selfGtag any
 * @param title string
 */
function createPageGoogleAnalytics( selfGtag: any, title: string ): void {
  selfGtag.query( 'event', 'page_view', {
    page_title: title,
  } )
}

/**
 * Função que identifica se é o step atual para
 * transformar em página no google analytics
 * @param selfGtag any
 * @param classe string
 * @param title string
 */
function createPageGoogleAnalyticsForStep( selfGtag: any, classe: string, title: string ): void {
  const elementStepAtual = document.getElementsByClassName( 'current' )[ 0 ];

  if( elementStepAtual ? elementStepAtual!.getElementsByClassName( classe ).length > 0 : false ) {
    createPageGoogleAnalytics( selfGtag, title );
  }

}

/**
 * Função utilizada para adicionar foco
 * em elementos label e input
 * @param selfGtag any
 */
  function updateGoogleAnalytics( selfGtag: any ): void {

    /**
     * Autenticação falha
     */
    createPageGoogleAnalyticsForStep( selfGtag, "authenticationFailed", "Autenticacao Falha" );

    /**
     * Grupo Person
     */
    createPageGoogleAnalyticsForStep( selfGtag, "TermOfUse", "Termo de uso" );
    createPageGoogleAnalyticsForStep( selfGtag, "fullName", "Nome completo" );
    createPageGoogleAnalyticsForStep( selfGtag, "gender", "Gênero" );
    createPageGoogleAnalyticsForStep( selfGtag, "nascimento", "Nascimento" );
    createPageGoogleAnalyticsForStep( selfGtag, "CNH", "CNH" );
    createPageGoogleAnalyticsForStep( selfGtag, "commercialPurposes", "Uso comercial" );
    createPageGoogleAnalyticsForStep( selfGtag, "isPCD", "PCD" );
    createPageGoogleAnalyticsForStep( selfGtag, "CPF", "CPF" );
    createPageGoogleAnalyticsForStep( selfGtag, "email", "email" );
    createPageGoogleAnalyticsForStep( selfGtag, "phone", "Celular" );
    createPageGoogleAnalyticsForStep( selfGtag, "boxConfirmedPerson", "Confirmacao grupo dados pessoais" );

    /**
     * Grupo Vehicle
     */
    createPageGoogleAnalyticsForStep( selfGtag, "typeVehicle", "Veiculo novo ou usado" );
    createPageGoogleAnalyticsForStep( selfGtag, "factureYear", "Ano de fabricação" );
    createPageGoogleAnalyticsForStep( selfGtag, "modelYear", "Ano do modelo" );
    createPageGoogleAnalyticsForStep( selfGtag, "brandVehicle", "Marca do veiculo" );
    createPageGoogleAnalyticsForStep( selfGtag, "modelVehicle", "Modelo do veiculo" );
    createPageGoogleAnalyticsForStep( selfGtag, "versionVehicle", "Versao do veiculo" );
    createPageGoogleAnalyticsForStep( selfGtag, "priceVehicle", "Valor veiculo" );
    createPageGoogleAnalyticsForStep( selfGtag, "boxConfirmedVehicle", "Confirmacao grupo veiculo" );

    /**
     * Grupo Financing
     */
    createPageGoogleAnalyticsForStep( selfGtag, "rangeNumber", "Entrada" );
    createPageGoogleAnalyticsForStep( selfGtag, "parcel", "Parcela" );
    createPageGoogleAnalyticsForStep( selfGtag, "boxConfirmedFinancing", "Confirmacao grupo finanças" );

    /**
     * Simulação
     */
    createPageGoogleAnalyticsForStep( selfGtag, "boxConfirmedSimulacao", "Confirmacao grupo simulação" );
  }

export { createPageGoogleAnalytics, createPageGoogleAnalyticsForStep, updateGoogleAnalytics }