
import { Component, Vue, Watch } from "vue-property-decorator";
import CInputIcon from "@/components/fields-step/CInputIcon/CInputIcon.vue";
import CButtonForm from "@/components/fields-step/CButtonForm/CButtonForm.vue";

import { validateEmail, validateCelphone } from "@/utils/validations";
import { IFormField } from "../FinancingStepGroup/interface/IFormField";
import useSimulationStore from "@/store/simulation";
import useAppConfigStore from "@/store/appConfig";
import useSubmissionProposalStore from "@/store/submissionProposal";
import { IRequestSendLink } from "@/services/interface/submissionProposal/ISubmissionProposal";
import QrcodeVue from 'qrcode.vue'

@Component({
  name: "SubmissionProposalGroup",
  components: {
    CInputIcon,
    CButtonForm,
    QrcodeVue,
  },
})
export default class SubmissionProposalGroup extends Vue {
  ehButtonWhatsapp: boolean = true;
  ehButtonEmail: boolean = true;
  //Valores dos campos
  celphone = {
    valid: true,
    messageError: "⚠ Campo necessário para envio do link!",
  } as IFormField;
  email = {
    valid: true,
    messageError: "⚠ Campo necessário para envio do link!",
  } as IFormField;
  //habilitar envio do link
  celphoneAbility: boolean = true;
  emailAbility: boolean = true;
  //apresentar icone de reload
  celphoneLoad: boolean = false;
  emailLoad: boolean = false;
  //apresentar info
  infoLinkVisible: boolean = false;

  simulationStore = useSimulationStore();
  appConfigStore = useAppConfigStore();
  submissionProposalStore = useSubmissionProposalStore();

  @Watch("isSubmissionProposal", { immediate: true })
  @Watch("isUpdateCelphone", { immediate: true })
  @Watch("isUpdateEmail", { immediate: true })
  updateValues(): void {
    if (this.isSubmissionProposal) {
      this.celphone.value = this.appConfigStore.dataFormPerson.celphone.value;
      this.celphone.value && this.celphone.value != ""
        ? (this.ehButtonWhatsapp = false)
        : (this.ehButtonWhatsapp = true);

      this.email.value = this.appConfigStore.dataFormPerson.email.value;
      this.email.value && this.email.value != ""
        ? (this.ehButtonEmail = false)
        : (this.ehButtonEmail = true);
    }
  }

  openLinkRedirect(): void {
    window.open(
      this.submissionProposalStore.dataFormResponseGetLink.url,
      "_blank"
    );
  }

  /**
   * Função de enviar link para whatsapp
   */
  sendLinkWhatsapp(): void {
    if (!this.validStepCurrentCelphone) {
      this.celphoneAbility = false;
      return;
    }
    this.celphoneLoad = true;
    this.sendLink(
      typeof this.celphone.value == "string"
        ? this.celphone.value.replace(/\D/g, "")
        : "",
      "W"
    );
  }

  /**
   * Função de enviar link para email
   */
  sendLinkEmail(): void {
    if (!this.validStepCurrentEmail) {
      this.emailAbility = false;
      return;
    }
    this.emailLoad = true;
    this.sendLink(
      typeof this.email.value == "string" ? this.email.value : "",
      "E"
    );
  }
  /**
   * Função para chamar serviço de enviar link
   * @param data *
   */
  async sendLink(contact: string, shippingOption: string): Promise<void> {
    const data: {
      request: IRequestSendLink;
      clientId: number | null | undefined;
    } = {
      request: {
        vendedorId:
          this.simulationStore.dataFormResponseSimulation.retorno?.institucional
            .vendedorId,
        contato: contact,
        opcaoEnvio: shippingOption,
      },
      clientId:
        this.simulationStore.dataFormResponseSimulation.retorno?.clienteId,
    };
    this.appConfigStore.$patch((state) => (state.loading = true));
    await this.submissionProposalStore
      .fetchSendLink(data)
      .then(() => {
        this.infoLinkVisible = true;
        this.appConfigStore.$patch(
          (state) => (state.titleRequestSuccess = "Link enviado!")
        );
        this.appConfigStore.$patch(
          (state) =>
            (state.descriptionRequestSuccess =
              shippingOption == "E"
                ? "Enviamos o link do cadastro para o seu email."
                : "Enviamos o link do cadastro para o seu whatsapp.")
        );
        this.appConfigStore.$patch((state) => (state.requestSuccess = true));
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.message != null &&
            error.response.data.message != undefined
          ) {
            this.appConfigStore.$patch(
              (state) =>
                (state.messageRequestFailed = error.response.data.message)
            );
          }
        }
        this.appConfigStore.$patch((state) => (state.requestFailed = true));
      })
      .finally(() =>
        this.appConfigStore.$patch((state) => (state.loading = false))
      );
  }

  /**
   * Função para validar se o número de celular está valido
   * @param event string
   */
  validInputCelphone(event: string): void {
    this.celphone.value = event;
    this.celphone.valid = false;

    //Desabilita campo apenas se
    //o campo estava ativo para envio anterioemente
    if (!this.celphoneLoad) {
      this.celphoneAbility = false;
    }

    this.celphoneLoad = false;

    //removendo notificação de campo com erro
    this.removeStyleError("#step-proposal-celphone");

    //Valida se o campo foi preenchido
    if (!this.celphone.value) {
      this.celphone.messageError = "⚠ Campo necessário para envio do link!";
      return;
    }

    if (!validateCelphone(this.celphone.value)) {
      this.celphone.messageError = "⚠ O número informado não é válido!";
      return;
    }
    this.celphoneAbility = true;
    this.celphone.valid = true;
  }

  /**
   * Função para validar se o email está valido
   * @param event string
   */
  validInputEmail(event: string): void {
    this.email.value = event;
    this.email.valid = false;

    if (this.emailLoad) {
      this.emailAbility = false;
    }
    this.emailLoad = false;

    //removendo notificação de campo com erro
    this.removeStyleError("#step-proposal-email");

    //Valida se o campo foi preenchido
    if (!this.email.value) {
      this.email.messageError = "⚠ Campo necessário para envio do link!";
      return;
    }

    //Valida se o email é válido
    if (!validateEmail(this.email.value)) {
      this.email.messageError = "⚠ O email informado não é válido!";
      return;
    }
    this.emailAbility = true;
    this.email.valid = true;
  }

  /**
   * Função para realizar a remoção do estilo de erro
   * @param id string
   */
  removeStyleError(id: string): void {
    const Stepfield = document.querySelector(id);
    if (!Stepfield) {
      return;
    }

    const field = Stepfield!.querySelector(".step-control");
    if (field) {
      field!.classList.remove("input-invalid");
      const spanInvalid = Stepfield!.querySelector(
        ".span-invalid-input-icon"
      ) as HTMLSpanElement;
      spanInvalid ? (spanInvalid.style.display = "none") : null;
    }
  }

  /***
   * Adicionar notificação de erro ou remove caso esteja valido
   */
  validStepCurrent(id: string, validField: boolean): boolean {
    const StepField = document.querySelector(id);
    const field = StepField!.querySelector(".step-control");

    if (validField) {
      if (field) {
        field!.classList.remove("input-invalid");
        const spanInvalid = StepField!.querySelector(
          ".span-invalid-input-icon"
        ) as HTMLSpanElement;
        spanInvalid ? (spanInvalid.style.display = "none") : null;
      }
      return true;
    }

    let displayNone = StepField!.querySelector(
      ".span-invalid-input-icon"
    ) as HTMLSpanElement;
    displayNone.style.display = "block";

    //Estilo para as notificações de campos inválidos
    if (field!.classList) {
      field!.classList.add("input-invalid");
    } else {
      field!.className += " " + "input-invalid";
    }

    return false;
  }

  get validStepCurrentCelphone(): boolean {
    return this.validStepCurrent(
      "#step-proposal-celphone",
      this.celphone.valid ? true : false
    );
  }

  get validStepCurrentEmail(): boolean {
    return this.validStepCurrent(
      "#step-proposal-email",
      this.email.valid ? true : false
    );
  }

  get isSubmissionProposal(): boolean {
    return this.appConfigStore.isSubmissionProposal;
  }

  get isUpdateCelphone(): IFormField {
    return this.appConfigStore.dataFormPerson.celphone;
  }

  get isUpdateEmail(): IFormField {
    return this.appConfigStore.dataFormPerson.email;
  }

  get visiblieButtonWhatsapp(): boolean {
    return (
      this.ehButtonWhatsapp &&
      (this.celphone.value == "" || !this.celphone.value)
    );
  }

  get visiblieInputWhatsapp(): boolean {
    return !this.ehButtonWhatsapp || this.celphone.value !== "";
  }

  get visiblieButtonEmail(): boolean {
    return this.ehButtonEmail && (this.email.value == "" || !this.email.value);
  }

  get visiblieInputEmail(): boolean {
    return !this.ehButtonEmail || this.email.value !== "";
  }

  get visiblieButtonLink(): boolean {
    return true;
  }
}
